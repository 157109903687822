import React from "react";

//Style
import "./AboutUsDepartmentsSection.css";

const AboutUsDepartmentsSection = () => {
  return (
    <section className="about-us-department-section">
      <div className="about-us-department-subcontainer">
        <h1 className="aud-title">Clientes Asegurados En Todo El País</h1>
        <div className="aud-content-container">
          <div className="aud-c-departments-container">
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/department-people-img.svg"
              alt=""
              className="aud-c-d-deco-img"
            />
            <p className="aud-c-deparment-elm">Amazonas (3)</p>
            <p className="aud-c-deparment-elm">Antioquia (1.471)</p>
            <p className="aud-c-deparment-elm">Arauca (66)</p>
            <p className="aud-c-deparment-elm">Atlántico (713)</p>
            <p className="aud-c-deparment-elm">Cundinamarca (10.400)</p>
            <p className="aud-c-deparment-elm">Bolívar (574)</p>
            <p className="aud-c-deparment-elm">Boyacá (760)</p>
            <p className="aud-c-deparment-elm">Caldas (456)</p>
            <p className="aud-c-deparment-elm">Caquetá (126)</p>
            <p className="aud-c-deparment-elm">Casanare (154)</p>
            <p className="aud-c-deparment-elm">Cauca (231)</p>
            <p className="aud-c-deparment-elm">Cesar (217)</p>
            <p className="aud-c-deparment-elm">Choco (91)</p>
            <p className="aud-c-deparment-elm">Córdoba (283)</p>
            <p className="aud-c-deparment-elm">Guajira (82)</p>
          </div>
          <div className="aud-c-departments-container">
            <p className="aud-c-deparment-elm">Guaviare (5)</p>
            <p className="aud-c-deparment-elm">Huila (414)</p>
            <p className="aud-c-deparment-elm">Magdalena (291)</p>
            <p className="aud-c-deparment-elm">Meta (398)</p>
            <p className="aud-c-deparment-elm">Nariño (321)</p>
            <p className="aud-c-deparment-elm">Norte de Santander (420)</p>
            <p className="aud-c-deparment-elm">Putumayo (77)</p>
            <p className="aud-c-deparment-elm">Quindío (167)</p>
            <p className="aud-c-deparment-elm">Risaralda (258)</p>
            <p className="aud-c-deparment-elm">San Andrés (14)</p>
            <p className="aud-c-deparment-elm">Santander (1.073)</p>
            <p className="aud-c-deparment-elm">Sucre (110)</p>
            <p className="aud-c-deparment-elm">Tolima (700)</p>
            <p className="aud-c-deparment-elm">Valle del Cauca (1.841)</p>
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/second-deco-department-img.svg"
              alt=""
              className="aud-c-d-second-deco-img"
            />
          </div>
          <div className="aud-c-departments-map-container">
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/about-us-map.svg"
              alt=""
              className="aud-c-dm-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsDepartmentsSection;
