import React from "react";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import PrivacyPolicySection from "../components/PrivacyPolicySection/PrivacyPolicySection";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Navbar />
      <PrivacyPolicySection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;
