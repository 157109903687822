import React, { useEffect, useState } from "react";

//Data
import peopleHomeBannerImg from "../json/PeopleHomeBannerImgs.json";
import peopleHomeBannerSmallTablet from "../json/PeopleHomeBannerImgsTabletSmall.json";
import peopleHomeBannerMobileImg from "../json/PeopleHomeBannerMobileImgs.json";
import insurancesGridData from "../json/InsurancesGridData.json";
import insurancesGridMobileData from "../json/InsuranceGridMobileData.json";

//Component
import Navbar from "../components/Navbar/Navbar";
import Banner from "../components/PeopleHomeComponents/Banner/Banner";
import InsurancePeopleGridSection from "../components/PeopleHomeComponents/InsurancePeopleGridSection/InsurancePeopleGridSection";
import ReviewSection from "../components/PeopleHomeComponents/ReviewSection/ReviewSection";
import FirstVideoSection from "../components/PeopleHomeComponents/FirstVideoSection/FirstVideoSection";
import PrizesCarouselSection from "../components/PeopleHomeComponents/PrizesCarouselSection/PrizesCarouselSection";
import CompanyPromotionSection from "../components/PeopleHomeComponents/CompanyPromotionSection/CompanyPromotionSection";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";

const PeopleHomeScreen = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      <Banner
        imgs={
          wide < 768
            ? peopleHomeBannerMobileImg.data
            : wide >= 768 && wide < 1024
            ? peopleHomeBannerSmallTablet.data
            : peopleHomeBannerImg.data
        }
        heightBanner={wide < 768 ? "272px" : "560px"}
        wide={wide}
      />
      <InsurancePeopleGridSection
        insurances={wide < 768 ? insurancesGridMobileData : insurancesGridData}
        wide={wide}
      />
      <ReviewSection />
      <FirstVideoSection />
      <PrizesCarouselSection />
      <CompanyPromotionSection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default PeopleHomeScreen;
