import React, { useState, useEffect } from "react";

//Data
import companyHomeBannerImg from "../json/CompanyHomeBannerImg.json";
import peopleHomeBannerSmallTablet from "../json/CompanyHomeBannerTabletImg.json";
import peopleHomeBannerMobileImg from "../json/CompanyHomeBannerMobileImg.json";

//Component
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import BannerWithSkewComponent from "../components/BannerWithSkewComponent/BannerWithSkewComponent";
import InsuranceCompanyGridSection from "../components/InsuranceCompanyGridSection/InsuranceCompanyGridSection";
import CompanyHomeFirstSection from "../components/CompanyHomeFirstSection/CompanyHomeFirstSection";
import CompanyHomeSecondSection from "../components/CompanyHomeSecondSection/CompanyHomeSecondSection";
import CompanyHomeThirdSection from "../components/CompanyHomeThirdSection/CompanyHomeThirdSection";
import CompanyHomeCounterSection from "../components/CompanyHomeCounterSection/CompanyHomeCounterSection";

const CompanyHomeScreen = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      <BannerWithSkewComponent
        imgs={
          wide < 768
            ? peopleHomeBannerMobileImg.data
            : wide >= 768 && wide < 1024
            ? peopleHomeBannerSmallTablet.data
            : companyHomeBannerImg.data
        }
        heightBanner={wide < 768 ? "272px" : "560px"}
        wide={wide}
      />
      <InsuranceCompanyGridSection />
      <CompanyHomeFirstSection />
      <CompanyHomeSecondSection />
      <CompanyHomeThirdSection />
      <CompanyHomeCounterSection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default CompanyHomeScreen;
