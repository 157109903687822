import React from "react";
import GridCarousel from "react-grid-carousel";

//Data
import insurerCarouselData from "../../../json/PartnerCarouselData.json";

//Style
import "./InsurancePartnerSection.css";

const InsurancePartnerSection = () => {
  return (
    <section className="insurance-partner-section">
      <div className="insurance-partner-subcontainer">
        <h1 className="ip-title">Aseguradoras aliadas</h1>
        <div className="ip-carousel-container">
          <GridCarousel
            cols={7}
            rows={1}
            gap={10}
            loop
            hideArrow={true}
            autoplay={3000}
            responsiveLayout={[
              {
                breakpoint: 767,
                cols: 1,
              },
              {
                breakpoint: 1023,
                cols: 5,
              },
            ]}
          >
            {insurerCarouselData &&
              insurerCarouselData.data.map((partner, i) => {
                return (
                  <GridCarousel.Item key={i}>
                    <div className="ipc-item-container">
                      <img
                        className="ipc-insurance-logo"
                        width="100%"
                        src={partner.img}
                        title={partner.name}
                        alt=""
                      />
                    </div>
                  </GridCarousel.Item>
                );
              })}
          </GridCarousel>
        </div>
      </div>
    </section>
  );
};

export default InsurancePartnerSection;
