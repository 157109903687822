import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//Data
import teamPageData from "../json/TeamPageData.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import AboutUsBanner from "../components/AboutUsBanner/AboutUsBanner";
import TeamPeopleSection from "../components/TeamPeopleSection/TeamPeopleSection";

const TeamScreen = () => {
  const [teamPagePeople, setTeamPagePeople] = useState({});

  const [teamPageBanner, setTeamPageBanner] = useState([]);

  const { name } = useParams();

  useEffect(() => {
    const selectedTeam = teamPageData.data.filter((team) => team.name === name);
    setTeamPagePeople(selectedTeam[0].team_page_data);
    setTeamPageBanner(selectedTeam[0].team_page_banner);
  }, [name]);

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      {teamPageBanner && (
        <AboutUsBanner imgs={teamPageBanner} wide={wide} />
      )}
      {teamPagePeople && <TeamPeopleSection sectionData={teamPagePeople} />}
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default TeamScreen;
