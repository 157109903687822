import React, { useState } from "react";
import GridCarousel from "react-grid-carousel";
import { Link } from "react-router-dom";

//Style
import "./GoogleReviewCards.css";

const GoogleReviewCards = ({ reviewData }) => {
  const [expandedReviewIndex, setExpandedReviewIndex] = useState(null);

  const formatDate = (timestamp) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[month]} ${year}`;
  };

  return (
    <div className="google-reviews-container">
      <div className="google-reviews-subcontainer">
        <div className="gr-title-container">
          <h1 className="gr-title">Excelente</h1>
          <img className="gr-stars-deco" src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/review-component/stars-deco.webp" alt="" />
          <p className="gr-subtitle">
            A base de <strong>{reviewData.user_ratings_total} reseñas</strong>
          </p>
          <Link
            target="_blank"
            to="https://www.google.com/search?q=cavca+seguros&oq=cavca+s&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYOTIHCAMQABiABDIHCAQQABiABDIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPKgCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x8e3f8541e192cb6b:0x5b19b045fccae105,1"
            className="gr-btn"
            title="Escribe una reseña"
          >
            Escribe una reseña
          </Link>
        </div>
        <div className="gr-cards-container">
          {reviewData.reviews &&
            reviewData.reviews.map((review, i) => {
              const isExpanded = expandedReviewIndex === i;
              return (
                <div
                  key={i}
                  className={`gr-card-container ${
                    isExpanded && "show-txt-card"
                  }`}
                >
                  <div className="grc-user-data-container">
                    <img
                      className="grc-user-profile-img"
                      src={review.profile_photo_url}
                      alt=""
                    />
                    <div className="grc-review-data">
                      <h1 className="grc-user-name">{review.author_name}</h1>
                      <p className="grc-review-datetime">
                        {formatDate(review.time)}
                      </p>
                    </div>
                  </div>
                  <div className="gr-review-stars-container">
                    <div className="gr-review-stars-subcontainer">
                      <div className="gr-rs-elm-container">
                        <img
                          className="gr-review-stars"
                          src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/review-component/stars-review.webp"
                          alt=""
                        />
                        <div
                          style={{ width: `${review.rating * 20}%` }}
                          className="stars-bg-color-yellow"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="gr-review-txt-container">
                    {isExpanded ? (
                      review.text
                    ) : (
                      <>
                        {review.text.length > 20 ? (
                          <>
                            {review.text.slice(0, 40)}
                            <button
                              className="read-more-btn"
                              onClick={() => setExpandedReviewIndex(i)}
                            >
                              Leer más
                            </button>
                          </>
                        ) : (
                          review.text
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="gr-cards-mobile-container">
          <GridCarousel
            responsiveLayout={[
              {
                breakpoint: 1023,
                cols: 3,
              },
              {
                breakpoint: 767,
                cols: 1,
              },
            ]}
            cols={1}
            rows={1}
            gap={30}
            loop
            autoplay={4000}
          >
            {reviewData.reviews &&
              reviewData.reviews.map((review, i) => {
                const isExpanded = expandedReviewIndex === i;
                return (
                  <GridCarousel.Item key={i}>
                    <div className="grcm-supercontainer">
                      <div
                        className={`gr-card-container ${
                          isExpanded && "show-txt-card"
                        }`}
                      >
                        <div className="grc-user-data-container">
                          <img
                            className="grc-user-profile-img"
                            src={review.profile_photo_url}
                            alt=""
                          />
                          <div className="grc-review-data">
                            <h1 className="grc-user-name">
                              {review.author_name}
                            </h1>
                            <p className="grc-review-datetime">
                              {formatDate(review.time)}
                            </p>
                          </div>
                        </div>
                        <div className="gr-review-stars-container">
                          <div className="gr-review-stars-subcontainer">
                            <div className="gr-rs-elm-container">
                              <img
                                className="gr-review-stars"
                                src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/review-component/stars-review.webp"
                                alt=""
                              />
                              <div
                                style={{ width: `${review.rating * 20}%` }}
                                className="stars-bg-color-yellow"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="gr-review-txt-container">
                          {isExpanded ? (
                            review.text
                          ) : (
                            <>
                              {review.text.length > 20 ? (
                                <>
                                  {review.text.slice(0, 40)}
                                  <button
                                    className="read-more-btn"
                                    onClick={() => setExpandedReviewIndex(i)}
                                  >
                                    Leer más
                                  </button>
                                </>
                              ) : (
                                review.text
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </GridCarousel.Item>
                );
              })}
          </GridCarousel>
        </div>
      </div>
    </div>
  );
};

export default GoogleReviewCards;
