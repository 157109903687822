import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { Link } from "react-router-dom";

//Style
import "./Banner.css";

const Banner = ({ imgs, heightBanner, wide }) => {
  return (
    <section className="people-home-banner-section">
      <div className="people-home-banner-subcontainer">
        <Carousel
          style={{
            height: heightBanner,
            width: "auto",
            objectFit: "cover",
          }}
          shouldLazyLoad={true}
          hasTransition={true}
          hasIndexBoard={false}
          hasMediaButton={false}
          shouldMaximizeOnClick={false}
          hasSizeButton={false}
          hasLeftButtonAtMax={false}
          hasRightButtonAtMax={false}
          hasSizeButtonAtMax={false}
          shouldSwipeOnMouse={true}
          hasLeftButton={wide < 768 ? false : "centerLeft"}
          hasRightButton={wide < 768 ? false : "centerRight"}
          objectFit={"cover"}
          hasThumbnails={false}
          hasThumbnailsAtMax={false}
          canAutoPlay={true}
          isAutoPlaying={true}
          autoPlayInterval={4000}
          hasDotButtons={"bottom"}
        >
          {imgs.map((img, i) => {
            return (
              <div key={i} className="text-slide">
                {img.hasTxt && (
                  <div className="ts-info-content">
                    <h1 className="ts-info-title">{img.txtTitle}</h1>
                    <p className="ts-info-subtitle">{img.txtSubtitle}</p>
                    <Link
                      title="¡Cotiza ya!"
                      to={img.txtRedirect}
                      className="ts-info-btn"
                    >
                      ¡Cotiza ya!
                    </Link>
                  </div>
                )}
                <img
                  width={"100%"}
                  height={heightBanner}
                  style={{ objectFit: "cover" }}
                  src={img.src}
                  alt=""
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default Banner;
