import React from "react";
import { Link } from "react-router-dom";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./ProductGridSection.css";

const ProductGridSection = ({ secondSectionData }) => {
  const elements = [];

  secondSectionData &&
    secondSectionData.second_section_grid_content.map((gridContent) => {
      return elements.push(
        <div className="pg-grid-card-container">
          <img src={gridContent.ssgc_img} alt="" className="pgg-img" />
          <h1 className="pgg-subtitle">{gridContent.ssgc_title}</h1>
          {gridContent.ssgc_has_subtitle && (
            <h2 className="pgg-small-subtitle">{gridContent.ssgc_subtitle}</h2>
          )}
          {gridContent.ssgc_has_content_txt && (
            <div className="pgg-txt-container">
              {gridContent.ssgc_content_txt.map((content, i) => {
                return <p key={i}>{content.ssgc_txt}</p>;
              })}
            </div>
          )}
          {gridContent.ssgc_has_list && (
            <ul className="pgg-txt-list-container">
              {gridContent.ssgc_list_content.map((listContent, i) => {
                return <li key={i}>{listContent.ssgc_list_elm}</li>;
              })}
            </ul>
          )}
          {gridContent.ssgc_has_button && (
            <div className="pgg-btn-container">
              <Link
                to={gridContent.sggc_btn_url}
                className="pgg-btn"
                title="Ver más información"
              >
                Ver más información
              </Link>
            </div>
          )}
        </div>
      );
    });

  return (
    <section className="product-grid-section">
      <div className="product-grid-subcontainer">
        <h1 className="pg-title">
          {secondSectionData && secondSectionData.second_section_title}
        </h1>
        <div
          className={`pg-grid-container ${
            secondSectionData && secondSectionData.second_section_grid_col === 2
              ? "pg-two-col"
              : secondSectionData &&
                secondSectionData.second_section_grid_col === 4
              ? "pg-four-col"
              : "pg-three-col"
          }`}
        >
          {secondSectionData &&
            secondSectionData.second_section_grid_content.map(
              (gridContent, i) => {
                if (gridContent.ssgc_is_empty) {
                  return <div key={i} style={{ opacity: "0" }}></div>;
                } else {
                  return (
                    <div key={i} className="pg-grid-card-container">
                      <img
                        src={gridContent.ssgc_img}
                        alt=""
                        className="pgg-img"
                      />
                      <h1 className="pgg-subtitle">{gridContent.ssgc_title}</h1>
                      {gridContent.ssgc_has_subtitle && (
                        <h2 className="pgg-small-subtitle">
                          {gridContent.ssgc_subtitle}
                        </h2>
                      )}
                      {gridContent.ssgc_has_content_txt && (
                        <div className="pgg-txt-container">
                          {gridContent.ssgc_content_txt.map((content, i) => {
                            return <p key={i}>{content.ssgc_txt}</p>;
                          })}
                        </div>
                      )}
                      {gridContent.ssgc_has_list && (
                        <ul className="pgg-txt-list-container">
                          {gridContent.ssgc_list_content.map(
                            (listContent, i) => {
                              return (
                                <li key={i}>{listContent.ssgc_list_elm}</li>
                              );
                            }
                          )}
                        </ul>
                      )}
                      {gridContent.ssgc_has_button && (
                        <div className="pgg-btn-container">
                          <Link
                            to={gridContent.sggc_btn_url}
                            className="pgg-btn"
                            title="Ver más información"
                          >
                            Ver más información
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                }
              }
            )}
        </div>
        <div className="pg-grid-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default ProductGridSection;
