import React, { useState } from "react";

//Style
import "./JobOfferFormComponent.css";

const JobOfferFormComponent = ({ offerName }) => {
  const offerNameFormatted = offerName
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [cv, setCv] = useState(null);

  const [jobOfferData, setjobOfferData] = useState({
    name: "",
    last_name: "",
    phone: "",
    email: "",
    comment: "",
  });

  const handleForm = (e) => {
    if (e.target.name === "cv") {
      setCv(e.target.files[0]);
    }

    if (e.target.name === "name" || e.target.name === "last_name") {
      if (e.target.value.trim() !== "") {
        e.target.classList.remove("error-form");
      } else {
        e.target.classList.add("error-form");
      }
    }

    if (e.target.name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailPattern.test(e.target.value)) {
        e.target.classList.remove("error-form");
      } else {
        e.target.classList.add("error-form");
      }
    }

    if (e.target.name === "phone") {
      e.target.value = e.target.value.replace(/\D/g, "");

      if (/^\s*3/.test(e.target.value.trim())) {
        e.target.classList.remove("error-form");
      } else {
        e.target.classList.add("error-form");
      }
    }

    setjobOfferData({
      ...jobOfferData,
      [e.target.name]: e.target.value,
    });

    if (
      jobOfferData.name &&
      jobOfferData.name !== "" &&
      jobOfferData.last_name &&
      jobOfferData.last_name !== "" &&
      jobOfferData.phone &&
      jobOfferData.phone !== "" &&
      jobOfferData.email &&
      jobOfferData.email !== ""
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("doc", cv);
    formData.append("type", "doc");
    formData.append("sender", ["talentohumano@cavca.com.co"]);
    formData.append(
      "subject",
      `Aplicación a oferta de empleo para ${offerNameFormatted} - ${jobOfferData.name} ${jobOfferData.last_name}`
    );
    formData.append(
      "text",
      `Hola, has recibido una aplicación a la siguiente oferta de empleo: ${offerNameFormatted} desde el sitio web de Cavca Seguros. Estos son los datos básicos de contacto: ${jobOfferData.name} ${jobOfferData.last_name}, número de teléfono: ${jobOfferData.phone}, correo electrónico: ${jobOfferData.email} y comentarios del postulante: ${jobOfferData.comment}.`
    );

    try {
      fetch("https://crediseguro-back.click/sendDocs", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            setjobOfferData({
              name: "",
              last_name: "",
              phone: "",
              email: "",
              comment: "",
            });
            setCv(null);
            setDisabledBtn(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="job-offer-form-container">
      <div className="job-offer-form-subcontainer">
        <div className="jof-form-container">
          <form
            onChange={handleForm}
            onSubmit={handleSubmit}
            className="jof-form-subcontainer"
          >
            <h1 className="jof-form-title">Aplica aquí</h1>
            <label className="jof-form-label">
              <p className="jof-form-txt">Hoja de vida en formato PDF*</p>
              <input type="file" name="cv" id="" className="jof-form-input" />
            </label>
            <div className="jof-dbl-input-container">
              <label className="jof-form-label">
                Nombres*
                <input
                  name="name"
                  type="text"
                  className="jof-form-input"
                  autoComplete="off"
                  value={jobOfferData.name}
                />
              </label>
              <label className="jof-form-label">
                Apellidos*
                <input
                  name="last_name"
                  type="text"
                  className="jof-form-input"
                  autoComplete="off"
                  value={jobOfferData.last_name}
                />
              </label>
            </div>
            <label className="jof-form-label">
              Correo electrónico*
              <input
                type="email"
                name="email"
                id=""
                className="jof-form-input"
                autoComplete="off"
                value={jobOfferData.email}
              />
            </label>
            <label className="jof-form-label">
              Número de teléfono*
              <input
                type="text"
                name="phone"
                id=""
                className="jof-form-input"
                autoComplete="off"
                value={jobOfferData.phone}
              />
            </label>
            <label className="jof-form-label">
              ¿Nos quieres decir algo más?
              <textarea
                type="text"
                name="comment"
                id=""
                className="jof-form-input"
                value={jobOfferData.comment}
              />
            </label>
            <button
              className="jof-form-btn"
              title="Aplicar"
              disabled={disabledBtn}
            >
              Aplicar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobOfferFormComponent;
