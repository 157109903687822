import React, { useState } from "react";
import { Link } from "react-router-dom";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import aboutUsBtnData from "../../json/AboutUsCultureBtnData.json";

//Style
import "./AboutUsCultureSection.css";

const AboutUsCultureSection = () => {
  const [iconTxt, setIconTxt] = useState({
    title: "",
    txt: "",
  });

  const [showIconTxt, setShowIconTxt] = useState(false);

  const handleShowIconInfo = (e) => {
    switch (e.target.title) {
      case "first":
        setShowIconTxt(true);
        setIconTxt({
          title: "Evolucionamos constantemente",
          txt: "Los productos de seguro evolucionan, llegan nuevas tendencias, nuevas aseguradoras al mercado y el alcance de cada póliza cambia de un año a otro. Por eso estamos en constante capacitación de nuestro equipo.",
        });
        break;
      case "second":
        setShowIconTxt(true);
        setIconTxt({
          title: "Nos ponemos la 10",
          txt: "Quienes nos eligen como asesores, deben estar seguros que, cuando necesiten utilizar los seguros que adquieren a través de nosotros, nos involucramos de manera permanente e inmediata en cada circunstancia, ¡Sin excusas!.",
        });
        break;
      case "third":
        setShowIconTxt(true);
        setIconTxt({
          title: "El cliente es lo más valioso",
          txt: "El servicio al cliente no es un departamento, es una actitud, por tal razón nuestros clientes recibirán asesoría con la importancia que se merecen. A través de la tecnología y nuestro equipo de trabajo brindamos un servicio excepcional.",
        });
        break;
        default:
          break;
    }
  };

  const handleHideIconInfo = () => {
    setShowIconTxt(false);
  };

  const elements = [];

  aboutUsBtnData &&
    aboutUsBtnData.data.map((btn) => {
      return elements.push(
        <div className="auce-img-container">
          <img
            src={btn.icon}
            alt=""
            className="auce-i-img"
            title="first"
            onMouseEnter={handleShowIconInfo}
            onMouseLeave={handleHideIconInfo}
          />
          <div className="auce-img-txt-container">
            <p className="auce-img-txt">{btn.txt}</p>
          </div>
        </div>
      );
    });

  return (
    <section className="about-us-culture-section">
      <div className="about-us-culture-subcontainer">
        <h1 className="auce-title">Nuestra cultura Cavca</h1>
        <div className="auce-content-container">
          <div className="auce-icons-container">
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/about-us-culture-3.webp"
              alt=""
              className="auce-i-img cursor"
              title="first"
              onMouseEnter={handleShowIconInfo}
              onMouseLeave={handleHideIconInfo}
            />
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/our-culture-plus-icon.webp"
              alt=""
              className="auce-i-img"
            />
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/about-us-culture-2.webp"
              alt=""
              title="second"
              onMouseEnter={handleShowIconInfo}
              onMouseLeave={handleHideIconInfo}
              className="auce-i-img cursor"
            />
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/our-culture-plus-icon.webp"
              alt=""
              className="auce-i-img"
            />
            <img
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/about-us-page/assets/about-us-culture-1.webp"
              alt=""
              title="third"
              onMouseEnter={handleShowIconInfo}
              onMouseLeave={handleHideIconInfo}
              className="auce-i-img cursor"
            />
          </div>
          <div
            className={`auce-icon-info-container ${
              showIconTxt && "show-icon-info"
            }`}
          >
            <div className="auce-ii-subcontainer">
              <h1 className="auce-ii-title">{iconTxt.title}</h1>
              <p className="auce-ii-txt">{iconTxt.txt}</p>
            </div>
          </div>
          <div className="auce-download-brochure-container">
            <Link
              className="auce-db-btn"
              target="_blank"
              title="Descarga Nuestro Brochure"
              to="https://cavcaseguros.com/wp-content/uploads/2022/03/Brochure-corporativo-Cavca-Seguros-2021.pdf"
            >
              Descarga Nuestro Brochure
            </Link>
          </div>
        </div>
        <div className="auce-content-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsCultureSection;
