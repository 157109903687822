import React from "react";
import { Link } from "react-router-dom";

//Style
import "./CompanyHomeFirstSection.css";

const CompanyHomeFirstSection = () => {
  return (
    <section className="company-home-first-section">
      <div className="company-home-first-subcontainer">
        <div className="chf-first-subsection">
          <div className="chf-fs-subcontainer">
            <h1 className="chf-fs-title">
              Seguros <strong>CRÉDITO</strong>
            </h1>
            <p className="chf-fs-content-txt">
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites. El seguro todo riesgo te acompaña
              donde vayas, cualquier incidente que tengas por donde transites.
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites.
            </p>
            <div className="chf-fs-btn-container">
              <Link
                className="chf-fs-btn"
                to="/seguros-empresariales"
                title="Acceder"
              >
                Acceder
              </Link>
            </div>
          </div>
        </div>
        <div className="chf-second-subsection">
          <div className="chf-fs-subcontainer right-pos">
            <h1 className="chf-fs-title white">
              Seguros <strong>ARL</strong>
            </h1>
            <p className="chf-fs-content-txt white">
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites. El seguro todo riesgo te acompaña
              donde vayas, cualquier incidente que tengas por donde transites.
            </p>
            <div className="chf-fs-btn-container">
              <Link
                className="chf-fs-btn"
                to="/seguros-empresariales/productos/arl"
                title="Acceder"
              >
                Acceder
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="chf-img-container">
        <img
          src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/company-main-page/bgs/company-home-first-section-bg.webp"
          alt=""
          className="chf-img-bg"
        />
      </div>
    </section>
  );
};

export default CompanyHomeFirstSection;
