import React from "react";

//Style
import "./ContactLocationSection.css";

const ContactLocationSection = () => {
  return (
    <section className="contact-location-section">
      <div className="contact-location-subcontainer">
        <iframe
          title="map"
          width="1024"
          height="300"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=1024&amp;height=300&amp;hl=en&amp;q=cavca%20seguros%20Bogot%C3%A1+(cavca%20seguros)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
        <script
          type="text/javascript"
          src="https://embedmaps.com/google-maps-authorization/script.js?id=52c6ffd00ab0069f557d0756ae3340541be86b29"
        ></script>
      </div>
    </section>
  );
};

export default ContactLocationSection;
