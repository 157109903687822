import React, { useEffect, useState } from "react";

//Data
import aboutUsBannerData from "../json/AboutUsBannerImgs.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import AboutUsCounterSection from "../components/AboutUsCounterSection/AboutUsCounterSection";
import AboutUsBanner from "../components/AboutUsBanner/AboutUsBanner";
import AboutUsTeamsSection from "../components/AboutUsTeamsSection/AboutUsTeamsSection";
import AboutUsDepartmentsSection from "../components/AboutUsDeparmentsSection/AboutUsDepartmentsSection";
import AboutUsCultureSection from "../components/AboutUsCultureSection/AboutUsCultureSection";
import FirstVideoSection from "../components/PeopleHomeComponents/FirstVideoSection/FirstVideoSection";

const AboutUsScreen = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };


  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      <AboutUsBanner imgs={aboutUsBannerData.data} wide={wide} />
      <AboutUsCounterSection />
      <AboutUsTeamsSection />
      <AboutUsDepartmentsSection />
      <AboutUsCultureSection />
      <FirstVideoSection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default AboutUsScreen;
