import React from "react";
import { Link } from "react-router-dom";

//Style
import "./ProductIntroSection.css";

const ProductIntroSection = ({ firstSectionData }) => {
  return (
    <section className="product-intro-section">
      <div className="product-intro-subcontainer">
        <h1 className="pi-title">
          {firstSectionData && firstSectionData.first_section_title}
        </h1>
        <div className="pi-content-container">
          <img
            src={firstSectionData && firstSectionData.first_section_deco_img}
            alt=""
            className="pi-content-img"
          />
          <div className="pi-content-txt-container">
            <div className="pi-content-txt-subcontainer">
              {firstSectionData &&
                firstSectionData.first_section_content.map((content, i) => {
                  return <p key={i}>{content.fsc_txt}</p>;
                })}
            </div>
            <div className="pi-content-btn-container">
              <Link
                to={firstSectionData && firstSectionData.first_section_btn_url}
                className="pi-content-btn"
                title="Hablar con un Asesor"
                target="_blank"
              >
                Hablar con un Asesor
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductIntroSection;
