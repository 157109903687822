import React, { useEffect, useState } from "react";

//Data
import jobOfferGridData from "../json/JobOfferCardsData.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import StaticBannerComponent from "../components/StaticBannerComponent/StaticBannerComponent";
import JobOfferAdviceSection from "../components/JobOfferAdviceSection/JobOfferAdviceSection";
import JobOfferGridSection from "../components/JobOfferGridSection/JobOfferGridSection";
import JobOfferPromotionVideoSection from "../components/JobOfferPromotionVideoSection/JobOfferPromotionVideoSection";

const JobOfferScreen = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      <StaticBannerComponent
        bannerImg={
          wide < 768
            ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/job-offer-page/job-offer-main-page/banner/mobile/job-offer-banner-mobile-img.webp"
            : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/job-offer-page/job-offer-main-page/banner/job-offer-banner-pc-img.webp"
        }
        height={wide < 768 ? "272px" : "560px"}
      />
      <JobOfferAdviceSection />
      <JobOfferGridSection gridData={jobOfferGridData.data} />
      <JobOfferPromotionVideoSection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default JobOfferScreen;
