import React from "react";

//Style
import "./ProductPageBanner.css";

//Components
import ProductBannerFormComponent from "../../ProductBannerFormComponent/ProductBannerFormComponent";

const ProductPageBanner = ({bannerImg, bannerFormData}) => {
  return (
    <section className="product-page-banner-section">
      <div className="product-page-banner-subcontainer">
        <img
          src={bannerImg ? bannerImg : ""}
          alt=""
          className="ppgb-img-banner"
        />
        <div className="ppb-form-container">
          <ProductBannerFormComponent bannerFormData={bannerFormData ? bannerFormData : ""} />
        </div>
      </div>
    </section>
  );
};

export default ProductPageBanner;
