import React from "react";

//Style
import "./ProductVideoSection.css";

const ProductVideoSection = ({ thirdSectionData }) => {
  return (
    <section className="product-video-section">
      <div className="product-video-subcontainer">
        <h1 className="pv-title">
          {thirdSectionData.third_section_has_custom_title
            ? thirdSectionData.third_section_custom_title
            : "Valores Agregados De Cavca"}
        </h1>
        <ul className="pv-content-container">
          {thirdSectionData.third_section_has_list &&
            thirdSectionData &&
            thirdSectionData.third_section_list.map((list, i) => {
              return <li key={i}>{list.tsl_elm}</li>;
            })}
        </ul>
        {thirdSectionData && thirdSectionData.third_section_has_video && (
          <div className="pv-video-container">
            <video className="pv-video" controls>
              <source
                src={thirdSectionData.third_section_video_url}
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductVideoSection;
