import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./CompanyHomeCounterSection.css";

//Data
import companyHomeCounterData from "../../json/AboutUsCounterData.json";

//Component
import CounterComponent from "../CounterComponent/CounterComponent";

const CompanyHomeCounterSection = () => {
  const elements = [];

  companyHomeCounterData &&
    companyHomeCounterData.data.map((counter) => {
      return elements.push(<CounterComponent info={counter} />);
    });

  return (
    <section className="company-home-counter-section">
      <div className="company-home-counter-subcontainer">
        <div className="auc-counter-info-container">
          {companyHomeCounterData.data.map((counter, i) => {
            return <CounterComponent key={i} info={counter} />;
          })}
        </div>
        <div className="auc-counter-info-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default CompanyHomeCounterSection;
