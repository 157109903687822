import React, { useState } from "react";

//Style
import "./ProductBannerFormComponent.css";

const ProductBannerFormComponent = ({ bannerFormData }) => {
  const [checkedValue, setCheckedValue] = useState(false);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [productFormData, setProductFormData] = useState({
    name: "",
    phone: "",
    email: "",
    advice_check: false,
  });

  const handleForm = (e) => {
    e.target.name === "advice_check" && setCheckedValue(!checkedValue);
    const valueCheck = e.target.name === "advice_check" && e.target.checked;

    if (e.target.name === "name") {
      if (e.target.value.trim() !== "") {
        e.target.parentElement.classList.remove("error-form");
      } else {
        e.target.parentElement.classList.add("error-form");
      }
    }

    if (e.target.name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailPattern.test(e.target.value)) {
        e.target.parentElement.classList.remove("error-form");
      } else {
        e.target.parentElement.classList.add("error-form");
      }
    }

    if (e.target.name === "phone") {
      e.target.value = e.target.value.replace(/\D/g, "");

      if (/^\s*3/.test(e.target.value.trim())) {
        e.target.parentElement.classList.remove("error-form");
      } else {
        e.target.parentElement.classList.add("error-form");
      }
    }

    if (e.target.name === "advice_check") {
      if (valueCheck === true) {
        e.target.parentElement.classList.remove("error");
      } else {
        e.target.parentElement.classList.add("error");
      }
    }

    setProductFormData({
      ...productFormData,
      [e.target.name]: e.target.value,
      advice_check: valueCheck,
    });

    if (
      productFormData.name &&
      productFormData.name !== "" &&
      productFormData.phone &&
      productFormData.phone !== "" &&
      productFormData.email &&
      productFormData.email !== "" &&
      valueCheck !== false
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {};

    switch (bannerFormData.banner_form_type) {
      case "automovil":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro Todo Riesgo`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro todo riesgo.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "motocicleta":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro para Moto`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de moto.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "bicicleta":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro para Bicicleta`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de bicicleta.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "hogar":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro para Hogar`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de hogar.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "vida":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro de Vida`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de vida.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "salud":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro de Salud`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de Salud.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "educativo":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro educativo`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro educativo.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "mascota":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro de Mascota`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de mascota.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
      case "viaje":
        data = {
          destinatario: bannerFormData.banner_form_receiver,
          asunto: `Solicitud de Cotización de Seguro de Viaje`,
          codigo: `
                <div>
                  <h1>Hola!!</h1>
                  <p>Has recibido información de un potencial cliente desde la pagina de <strong>Cavca Seguros</strong> para un seguro de viaje.</p>
                  <p>Aqui podras ver la información del usuario:</p>
                  <ul>
                    <li>Nombre: <strong>${productFormData.name}</strong></li>
                    <li>Correo eléctronico: <strong>${productFormData.email}</strong></li>
                    <li>Celular: <strong>${productFormData.phone}</strong></li>
                  </ul>
                </div>
              `,
        };
        break;
        default:
          break;
    }

    fetch("https://crediseguro-back.click/send", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json().then(() => ({
          status: res.status,
        }));
      })
      .then((data) => {
       
      })
      .catch((err) => {
        setCheckedValue(false);
        setProductFormData({
          name: "",
          phone: "",
          email: "",
          advice_check: false,
        });
      });
  };

  return (
    <form
      onChange={handleForm}
      onSubmit={handleSubmit}
      className="product-banner-form-container"
    >
      <h1 className="pbf-title">{bannerFormData.banner_form_title}</h1>
      <label className="pbf-input-container">
        <input
          type="text"
          className="pbf-input"
          placeholder="*Nombre Completo"
          maxLength="40"
          name="name"
          value={productFormData.name}
          required
        />
      </label>
      <label className="pbf-input-container">
        <input
          type="text"
          className="pbf-input"
          placeholder="*Teléfono"
          name="phone"
          value={productFormData.phone}
          maxLength={10}
          required
        />
      </label>
      <label className="pbf-input-container">
        <input
          type="email"
          className="pbf-input"
          placeholder="*Correo Electrónico"
          name="email"
          value={productFormData.email}
          required
        />
      </label>
      <div className="pbf-advice-container">
        <input
          name="advice_check"
          checked={checkedValue}
          className="pbf-advice-checkbox"
          type="checkbox"
        />
        <p className="pbf-advice-txt">
          Sí, estoy de acuerdo con la política de tratamiento de datos
          personales.
        </p>
      </div>
      <div className="pbf-btn-container">
        <button
          className="pbf-btn"
          title="Cotizar Ahora"
          disabled={disabledBtn}
        >
          Cotizar Ahora
        </button>
      </div>
    </form>
  );
};

export default ProductBannerFormComponent;
