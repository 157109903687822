import React from "react";
import { Link } from "react-router-dom";

//Style
import "./InsuranceGridMobileComponent.css";

const InsuranceGridMobileComponent = ({ gridBtn }) => {
  return (
    <div className="insurance-grid-mobile-component-container">
      {gridBtn.map((btn, i) => {
        return (
          <Link
            key={i}
            className="igmc-icon-btn"
            title={btn.title}
            to={btn.href}
          >
            <img name={i} className="igmc-icon" src={btn.icon} alt="" />
            <p className="igmc-subtitle">{btn.subtitle}</p>
          </Link>
        );
      })}
    </div>
  );
};

export default InsuranceGridMobileComponent;
