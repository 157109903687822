import React from "react";
import { Link } from "react-router-dom";

//Style
import "./ProductSmallBannerSection.css";

const ProductSmallBannerSection = () => {
  return (
    <section className="product-small-banner-section">
      <img
        src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/product-page/arl/assets/small-banner.webp"
        className="psb-img"
        alt=""
      />
      <div className="product-small-banner-subcontainer">
        <div className="psb-grid-container">
          <div className="psb-elm-container">
            <h1 className="psb-title">Intermediación con las ARL</h1>
            <Link
              to="/seguros-empresariales/productos/arl/informacion/intermediacion-con-las-arl"
              className="psb-btn"
              title="Ver más información"
            >
              Ver más información
            </Link>
          </div>
          <div className="psb-elm-container">
            <h1 className="psb-title">Prestadores de servicios SST</h1>
            <Link
              to="/seguros-empresariales/productos/arl/informacion/prestadores-de-servicios-sst"
              className="psb-btn"
              title="Ver más información"
            >
              Ver más información
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSmallBannerSection;
