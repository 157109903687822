import React, { useState } from "react";
import { Link } from "react-router-dom";

//Data
import NavbarSocialMedia from "../../json/NavbarSocialMediaData.json";

//Style
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <nav className="navbar-container">
      <div className="navbar-subcontainer">
        <div className="logo-container">
          <Link to="/">
            <img className="navbar-logo" src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/assets/logo-cavca.webp" alt="" />
          </Link>
        </div>
        <ul className="n-menus-container">
          <li>
            <Link to="https://cavca.my.salesforce.com/">
              Acceso Funcionarios
            </Link>
          </li>
          <li>
            <Link to="#">Productos</Link>
            <ul>
              <li>
                <Link to="/seguros-personales">Personas</Link>
                <ul>
                  <li>
                    <Link to="/seguros-personales/productos/automovil">
                      Auto
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/motocicleta">
                      Moto
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/hogar">Hogar</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/vida">Vida</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/salud">Salud</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/bicicleta">
                      Bicicleta
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/educativo">
                      Educativo
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/mascota">
                      Mascota
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/viaje">Viajes</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/seguros-empresariales">Empresas</Link>
                <ul>
                  <li>
                    <Link to="/">
                      Crédito
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/arl">ARL</Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/vehiculos">
                      Vehiculos
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/pyme">PYME</Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/construccion">
                      Construcción
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">Nosotros</Link>
            <ul>
              <li>
                <Link to="/nosotros">Nosotros</Link>
              </li>
              <li>
                <Link to="/contactanos">Contáctanos</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/ofertas-de-empleo">Ofertas de Empleo</Link>
          </li>
          <li>
            <div className="nbi-social-media-container">
              {NavbarSocialMedia.data.map((sm, i) => {
                return (
                  <Link
                    title={sm.title}
                    className="nbi-sm-icon-container no-apply-border"
                    to={sm.href}
                    key={i}
                    target="_blank"
                    onMouseEnter={() => setHoveredIndex(i)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <img
                      className="nbi-sm-icon"
                      src={hoveredIndex === i ? sm.hover : sm.src}
                      alt=""
                    />
                  </Link>
                );
              })}
            </div>
          </li>
        </ul>
        <div
          onClick={() => setShowMenu(!showMenu)}
          className="n-menu-ham-icon-container"
        >
          <img className="n-menu-icon" src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/assets/ham-menu-icon.webp" alt="" />
        </div>
        <ul
          className={`n-menu-mobile-container ${
            showMenu ? "show-mb-menu" : "hide-mb-menu"
          }`}
        >
          <li>
            <Link to="https://cavca.my.salesforce.com/">
              Acceso Funcionarios
            </Link>
          </li>
          <li>
            <Link to="#">Productos</Link>
            <ul className="nmm-list-container">
              <li>
                <Link to="/seguros-personales">Personas</Link>
                <ul className="nmm-sublist-container">
                  <li>
                    <Link to="/seguros-personales/productos/automovil">
                      Auto
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/motocicleta">
                      Moto
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/hogar">Hogar</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/vida">Vida</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/salud">Salud</Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/bicicleta">
                      Bicicleta
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/educativo">
                      Educativo
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/mascota">
                      Mascota
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-personales/productos/viaje">Viajes</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/seguros-empresariales">Empresas</Link>
                <ul className="nmm-sublist-container">
                  <li>
                    <Link to="/">
                      Crédito
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/arl">ARL</Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/vehiculos">
                      Vehiculos
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/pyme">PYME</Link>
                  </li>
                  <li>
                    <Link to="/seguros-empresariales/productos/construccion">
                      Construcción
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">Nosotros</Link>
            <ul className="nmm-list-container">
              <li>
                <Link to="/nosotros">Nosotros</Link>
              </li>
              <li>
                <Link to="/contactanos">Contáctanos</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/ofertas-de-empleo">Ofertas de Empleo</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
