import React from "react";
import { Link } from "react-router-dom";

//Style
import "./HomeBody.css";

const HomeBody = () => {
  return (
    <div className="home-body-container">
      <div className="home-body-subcontainer">
        <div className="hb-bg-img-container">
          <img src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/bgs/man-bg.webp" alt="" className="hb-bg-img" />
          <img
            src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/bgs/man-split-bg-img.webp"
            alt=""
            className="hb-bg-img-mobile"
          />
        </div>
        <section className="hb-info-container">
          <div className="hb-info-subcontainer">
            <h1 className="hb-info-title">
              Seguros <strong>Personales</strong>
            </h1>
            <p className="hb-info-content">
              Invertir en seguros es una forma inteligente de proteger tu
              patrimonio y garantizar la seguridad financiera de tu familia a
              largo plazo.
            </p>
            <div className="hb-info-btn-container">
              <Link
                className="hb-info-btn"
                to="/seguros-personales"
                title="Ingresa Aqui"
              >
                Ingresa Aqui
              </Link>
            </div>
            <div className="hb-info-deco-img-container">
              <img
                src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/assets/contract-img.webp"
                alt=""
                className="hb-info-img"
              />
            </div>
          </div>
          <div className="hb-info-subcontainer mb-view-hbis">
            <h1 className="hb-info-title">
              Seguros <strong>Empresariales</strong>
            </h1>
            <p className="hb-info-content mb-view-hbic">
              Los seguros empresariales son esenciales para proteger tu negocio
              y reducir riesgos, contribuyendo al exito de tu negocio.
            </p>
            <div className="hb-info-btn-container company-info">
              <Link
                className="hb-info-btn company-btn"
                to="/seguros-empresariales"
                title="Ingresa Aqui"
              >
                Ingresa Aqui
              </Link>
            </div>
            <div className="hb-info-deco-img-container company-img-info">
              <img
                src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/assets/contract-red-shield-img.webp"
                alt=""
                className="hb-info-img"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomeBody;
