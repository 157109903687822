import React from 'react'

//Components
import HomeBody from '../components/Pages/HomeBody/HomeBody'
import NavbarHome from '../components/NavbarHome/NavbarHome'

const Home = () => {
  return (
    <>
        <NavbarHome />
        <HomeBody />
    </>
  )
}

export default Home