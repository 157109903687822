import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//data
import productPageData from "../json/ProductPagesData.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import ProductPageBanner from "../components/ProductPageScreen/ProductPageBanner/ProductPageBanner";
import ProductIntroSection from "../components/ProductIntroSection/ProductIntroSection";
import ProductGridSection from "../components/ProductGridSection/ProductGridSection";
import ProductVideoSection from "../components/ProductVideoSection/ProductVideoSection";
import ProductTabSection from "../components/ProductTabSection/ProductTabSection";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import ProductSmallBannerSection from "../components/ProductSmallBannerSection/ProductSmallBannerSection";

const ProductPageScreen = () => {
  const [productPage, setProductPage] = useState({});

  const { name } = useParams();

  useEffect(() => {
    const selectedProduct = productPageData.data.filter(
      (product) => product.name === name
    );
    setProductPage(selectedProduct[0]);
  }, [name]);

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      {productPage && (
        <>
          <ProductPageBanner
            bannerImg={
              wide < 768
                ? productPage.banner_img_mobile
                : productPage.banner_img
            }
            bannerFormData={productPage.banner_form_data}
          />
          <ProductIntroSection
            firstSectionData={productPage.first_section_data}
          />
          {productPage.has_second_section && (
            <ProductGridSection
              secondSectionData={productPage.second_section_data}
              wide={wide}
            />
          )}
          {productPage.has_small_banner && <ProductSmallBannerSection />}
          {productPage.has_third_section && (
            <ProductVideoSection
              thirdSectionData={productPage.third_section_data}
            />
          )}
          {productPage.has_fourth_section && (
            <ProductTabSection
              fourthSectionData={productPage.fourth_section_data}
            />
          )}
        </>
      )}
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default ProductPageScreen;
