import React from "react";
import { Link } from "react-router-dom";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./ProductDetailSecondSection.css";

const ProductDetailSecondSection = ({ sectionData }) => {
  const elements = [];

  sectionData &&
    sectionData.ssd_steps_data.map((step) => {
      return elements.push(
        <div className="pds-i-content-elm-container">
          <img src={step.ssd_sd_icon} alt="" className="pds-i-ce-img" />
          <h1 className="pds-i-ce-title">{step.ssd_sd_title}</h1>
        </div>
      );
    });

  return (
    <section className="product-detail-second-section">
      {sectionData && (
        <div className="product-detail-second-subcontainer">
          {sectionData.ssd_has_complement_info && (
            <>
              <h1 className="pds-more-info-title">
                {sectionData.ssd_complement_info.ssd_ci_title}
              </h1>
              <div className="pds-more-info-content-container">
                {sectionData.ssd_complement_info.ssd_ci_content.map((p, i) => {
                  return (
                    <p key={i} className="pds-mic-txt">
                      {p.ssd_ci_txt}
                    </p>
                  );
                })}
              </div>
            </>
          )}
          <h1 className="pds-info-title">{sectionData.ssd_title}</h1>
          <div className="pds-i-content-container">
            {sectionData.ssd_steps_data.map((step, i) => {
              return (
                <div className="pds-i-content-elm-container">
                  <img src={step.ssd_sd_icon} alt="" className="pds-i-ce-img" />
                  <h1 className="pds-i-ce-title">{step.ssd_sd_title}</h1>
                </div>
              );
            })}
          </div>
          <div className="pds-i-content-container-mobile">
            <CarouselDos
              children={elements}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              isAutoPlaying={true}
              hasDotButtons={false}
              hasLeftButton={false}
              hasRightButton={false}
              hasMediaButton={false}
              hasIndexBoard={false}
              hasThumbnails={false}
              hasSizeButton={false}
              transitionSpeed={0.1}
            />
          </div>
          <div className="pds-btn-container">
            <Link
              title="Hablar con un asesor"
              target="_blank"
              to={sectionData.ssd_button_data.ssd_bd_href}
              className="pds-btn"
            >
              Hablar con un asesor
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProductDetailSecondSection;
