import React from "react";

//Style
import "./CompanyPromotionSection.css";

const CompanyPromotionSection = () => {
  return (
    <section className="company-promotion-section">
      <div className="company-promotion-subcontainer">
        <div className="cp-video-container">
          <iframe
            className="cp-video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/zqDFXmSerzQ?si=nUkRLI-gser3pTdh"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          {/* <video className="cp-video" controls>
            <source src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/videos/promote-video.mp4" type="video/mp4" />
          </video> */}
        </div>
      </div>
    </section>
  );
};

export default CompanyPromotionSection;
