import React, { useEffect, useState } from "react";

//Style
import "./ReviewSection.css";

//Components
import GoogleReviewCards from "../../GoogleReviewCards/GoogleReviewCards";

const ReviewSection = () => {
  const [reviewData, setReviewData] = useState({});

  useEffect(() => {
    try {
      fetch("https://crediseguro-back.click/getCavcaReview", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setReviewData(data);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const calculateStarsWidth = () => {
    if (!reviewData.rating) return 0;

    return (parseFloat(reviewData.rating) / 5) * 100;
  };

  return (
    <section className="review-section">
      <div className="r-stars-subsection">
        <div className="r-stars-container">
          <div className="r-stars-rate-container">
            <img
              className="r-stars-img"
              src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/review-component/stars-review.webp"
              alt=""
            />
            <div
              style={{ width: calculateStarsWidth() + "%" }}
              className="stars-bg-color"
            ></div>
          </div>
        </div>
      </div>
      <div className="r-stars-txt-container">
        <h1 className="r-stars-txt">
          La compañía intermediaria de seguros de (casi) 5 estrellas
        </h1>
      </div>
      <div className="review-subcontainer">
        <div className="r-cards-container">
          <GoogleReviewCards reviewData={reviewData} />
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
