import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./AboutUsBanner.css";

const AboutUsBanner = ({ imgs, wide }) => {
  console.log("a")
  return (
    <section className="about-us-banner-section">
      <div className="about-us-banner-subcontainer">
        <Carousel
          style={{
            height: wide < 768 ? "272px" : "560px",
            width: "auto",
            objectFit: "cover",
          }}
          images={imgs}
          shouldLazyLoad={true}
          hasTransition={true}
          hasIndexBoard={false}
          hasMediaButton={false}
          shouldMaximizeOnClick={false}
          hasSizeButton={false}
          hasLeftButtonAtMax={false}
          hasRightButtonAtMax={false}
          hasSizeButtonAtMax={false}
          shouldSwipeOnMouse={imgs.length > 1 ? true : false}
          objectFit={"cover"}
          hasThumbnails={false}
          hasThumbnailsAtMax={false}
          canAutoPlay={true}
          isAutoPlaying={true}
          autoPlayInterval={4000}
          hasDotButtons={imgs.length > 1 ? "bottom" : false}
          hasRightButton={imgs.length > 1 ? "centerRight" : false}
          hasLeftButton={imgs.length > 1 ? "centerLeft" : false}
        />
      </div>
    </section>
  );
};

export default AboutUsBanner;
