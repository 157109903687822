import React from "react";

//Style
import "./JobOfferPromotionVideoSection.css";

const JobOfferPromotionVideoSection = () => {
  return (
    <section className="job-offer-promotion-video-section">
      <div className="job-offer-promotion-video-subcontainer">
        <h1 className="jopv-title">
          Buscamos personas innovadoras que quieran ponerse la “10” y que sepan
          que el cliente es lo más importante
        </h1>
        <div className="jopv-content-container">
          <video className="jopv-video-container" controls>
            <source src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/job-offer-page/job-offer-main-page/videos/job-offer-video.mp4" type="" />
          </video>
          <div className="jopv-video-txt">
            <p className="jopv-vt-txt">
              Para Cavca Seguros, lo más importante son las personas, por eso le
              apostamos como compañía al desarrollo sostenible y social de la
              región, sin importar los estereotipos socio-culturales. Es por
              ello que, trabajamos todos los días por un propósito y buscamos
              que los “Cavcalievers” que quieran formar parte de este proyecto y
              ser los elefantes naranjas del sector asegurador, no duden en
              contactarnos, porque somos una empresa joven, dinámica y sobre
              todo incluyente.
            </p>
            <p className="jopv-vt-txt">
              Si piensas que tienes talento que aportar a nuestra compañía, toda
              la actitud y las aptitudes necesarias para formar parte del TEAM
              CAVCA, ¡te esperamos!.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobOfferPromotionVideoSection;
