import React from "react";
import { Link } from "react-router-dom";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import teamBtnData from "../../json/AboutUsTeamBtnData.json";

//Style
import "./AboutUsTeamsSection.css";

const AboutUsTeamsSection = () => {
  const elements = [];

  teamBtnData &&
    teamBtnData.data.map((team) => {
      return elements.push(
        <div className="aut-tb-elm-container">
          <img src={team.icon} alt="" className="aut-tb-e-img" />
          <Link className="aut-tb-e-btn" to={team.redirect} title={team.title}>
            {team.title}
          </Link>
        </div>
      );
    });

  return (
    <section className="about-us-teams-section">
      <div className="about-us-teams-subcontainer">
        <h1 className="aut-title">Nuestro Equipo</h1>
        <div className="aut-team-btns-container">
          {teamBtnData &&
            teamBtnData.data.map((team, i) => {
              return (
                <div key={i} className="aut-tb-elm-container">
                  <img src={team.icon} alt="" className="aut-tb-e-img" />
                  <Link
                    className="aut-tb-e-btn"
                    to={team.redirect}
                    title={team.title}
                  >
                    {team.title}
                  </Link>
                </div>
              );
            })}
        </div>
        <div className="aut-team-btns-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsTeamsSection;
