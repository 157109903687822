import React from "react";

//Style
import "./ProductDetailFourthSection.css";

const ProductDetailFourthSection = ({ sectionData }) => {
  return (
    <section className="product-detail-fourth-section">
      <div className="product-detail-fourth-subcontainer">
        <h1 className="pdf-title">{sectionData && sectionData.fsd_title}</h1>
        <ul className="pdf-list-container">
          {sectionData &&
            sectionData.fsd_list.map((elm, i) => {
              return (
                <li key={i} className="pdf-list-elm">
                  {elm.fsd_list_txt}
                </li>
              );
            })}
        </ul>
        <p className="pdf-advice-txt">{sectionData.fsd_advice_txt}</p>
      </div>
    </section>
  );
};

export default ProductDetailFourthSection;
