import React from "react";

//Style
import "./ProductDetailBannerSection.css";

const ProductDetailBannerSection = ({ bannerImg }) => {
  return (
    <section className="product-detail-banner-section">
      <div className="product-detail-banner-subcontainer">
        <img src={bannerImg} alt="" className="pdb-img" />
      </div>
    </section>
  );
};

export default ProductDetailBannerSection;
