import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import contactData from "../../json/ContactData.json";

//Style
import "./ContactSection.css";

const ContactSection = () => {
  const elements = [];

  contactData &&
    contactData.data.map((contact) => {
      return elements.push(
        <div className="c-ci-elm-container">
          <img src={contact.icon} alt="" className="c-ci-e-img" />
          <h1 className="c-ci-e-title">{contact.title}</h1>
          <p className="c-ci-e-txt">{contact.txt}</p>
        </div>
      );
    });

  return (
    <section className="contact-section">
      <div className="contact-subcontainer">
        <h1 className="c-title">Datos de contacto</h1>
        <div className="c-contact-info-container">
          {contactData &&
            contactData.data.map((contact, i) => {
              return (
                <div className="c-ci-elm-container">
                  <img src={contact.icon} alt="" className="c-ci-e-img" />
                  <h1 className="c-ci-e-title">{contact.title}</h1>
                  <p className="c-ci-e-txt">{contact.txt}</p>
                </div>
              );
            })}
        </div>
        <div className="c-contact-info-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
