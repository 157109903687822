import React from "react";
import { Link } from "react-router-dom";

//Style
import "./CompanyHomeSecondSection.css";

const CompanyHomeSecondSection = () => {
  return (
    <section className="company-home-second-section">
      <div className="company-home-second-subcontainer">
        <div className="chs-first-subsection">
          <div className="chs-fs-subcontainer">
            <h1 className="chs-fs-title">
              Seguros <strong>VEHICULOS</strong>
            </h1>
            <p className="chs-fs-content-txt">
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites. El seguro todo riesgo te acompaña
              donde vayas, cualquier incidente que tengas por donde transites.
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites.
            </p>
            <div className="chs-fs-btn-container">
              <Link
                className="chs-fs-btn"
                to="/seguros-empresariales/productos/vehiculos"
                title="Acceder"
              >
                Acceder
              </Link>
            </div>
          </div>
        </div>
        <div className="chs-second-subsection">
          <div className="chs-fs-subcontainer right-pos">
            <h1 className="chs-fs-title">
              Seguros <strong>PYME</strong>
            </h1>
            <p className="chs-fs-content-txt">
              El seguro todo riesgo te acompaña donde vayas, cualquier incidente
              que tengas por donde transites. El seguro todo riesgo te acompaña
              donde vayas, cualquier incidente que tengas por donde transites.
            </p>
            <div className="chs-fs-btn-container">
              <Link
                className="chs-fs-btn"
                to="/seguros-empresariales/productos/pyme"
                title="Acceder"
              >
                Acceder
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="chs-img-container">
        <img
          src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/company-main-page/bgs/company-home-second-section-bg.webp"
          alt=""
          className="chs-img-bg"
        />
      </div>
      <div className="skew-bottom"></div>
    </section>
  );
};

export default CompanyHomeSecondSection;
