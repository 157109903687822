import React from "react";

//Style
import "./PrivacyPolicySection.css";

const PrivacyPolicySection = () => {
  return (
    <section className="privacy-policy-section">
      <div className="privacy-policy-subcontainer">
        <h1 className="pp-title">Políticas de Privacidad</h1>
        <h2 className="pp-subtitle">
          Política de tratamiento de datos personales de CAVCA LTDA.
        </h2>
        <h3 className="pp-subtitle-small">I. OBJETIVO</h3>
        <p className="pp-txt">
          Esta política de Tratamiento de Datos Personales{" "}
          <strong>(en adelante la “Política”)</strong> tiene como objeto
          desarrollar el derecho constitucional al Hábeas Data, que tienen todas
          las personas a las que <strong>CAVCA LTDA</strong>, le realiza
          tratamiento de información de carácter personal conforme con la
          legislación vigente.
        </p>
        <p className="pp-txt">
          <strong>CAVCA LTDA</strong> actúa en calidad de Responsable y/o
          Encargado y es una sociedad de responsabilidad limitada constituida de
          conformidad con las Leyes de Colombia, identificada con Nit. No.
          900.344.345-7, con domicilio en Bogotá D.C. en la carrera 22 No. 143 –
          40 <strong>(en adelante “CAVCA”)</strong>.
        </p>
        <p className="pp-txt">
          En virtud de lo anterior, esta política recoge las normas que regirán
          el Tratamiento de Datos Personales de todos los Titulares que tengan
          una relación o vínculo con <strong>CAVCA</strong> y sienta las bases
          de los principios y requisitos que deben observarse en el tratamiento
          de DATOS PERSONALES por parte de <strong>CAVCA</strong> o de cualquier
          funcionario y/o empleado de <strong>CAVCA</strong> o de terceros que
          este designe como proveedor.
        </p>
        <h3 className="pp-subtitle-small">II. ALCANCE</h3>
        <p className="pp-txt">
          La presente Política es de aplicación para todos los proveedores,
          funcionarios, empleados, clientes, aliados que, en desarrollo de sus
          actividades, tengan acceso a las Bases de Datos creadas por{" "}
          <strong>CAVCA</strong> para las finalidades que se describen más
          adelante.
        </p>
        <h3 className="pp-subtitle-small">III. DEFINICIONES</h3>
        <p className="pp-txt">
          Los siguientes términos definidos se deben tener en cuenta al momento
          de aplicar e interpretar la presente Política:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>Autorización:</strong> consentimiento previo, expreso e
            informado del Titular para llevar a cabo el Tratamiento de Datos
            Personales.
          </li>
          <li className="pp-list-elm">
            <strong>Aviso de Privacidad:</strong> documento apéndice de esta
            Política, físico, electrónico o en cualquier otro formato generado
            por <strong>CAVCA</strong>, que es puesto a disposición del Titular,
            previo al tratamiento de sus Datos Personales y en el cual se le
            comunica al Titular de la información relativa a la existencia de
            políticas de tratamiento de la información, la forma de acceder a
            estas y las características del Tratamiento que tendrán sus Datos
            Personales, cuando no es posible poner a disposición del Titular la
            política de tratamiento.
          </li>
          <li className="pp-list-elm">
            <strong>Base de Datos Personales:</strong> es todo un conjunto
            organizado de datos personales los cuales va a ser objeto de
            Tratamiento.
          </li>
          <li className="pp-list-elm">
            <strong>Datos Personales:</strong> cualquier información vinculada o
            que pueda asociarse a una o varias personas naturales determinadas o
            determinables.
          </li>
          <li className="pp-list-elm">
            <strong>Dato Personal Sensible:</strong> aquellos que afectan la
            intimidad del Titular o cuyo uso indebido puede generar su
            discriminación, tales como aquellos que revelen el origen racial o
            étnico, la orientación política, las convicciones religiosas o
            filosóficas, la pertenencia a sindicatos, organizaciones sociales,
            de derechos humanos o que promueva intereses de cualquier partido
            político o que garanticen los derechos y garantías de partidos
            políticos de oposición así como los datos relativos a la salud, a la
            vida sexual y los datos biométricos.
          </li>
          <li className="pp-list-elm">
            <strong>Datos Públicos:</strong> aquellos que afectan la intimidad
            del Titular o cuyo uso es el dato que no sea privado o sensible. Son
            considerados Datos Públicos, entre otros, los datos relativos al
            estado civil de las personas, a su profesión u oficio, ya su calidad
            de comerciante o de servidor público. Por su naturaleza, los datos
            públicos pueden estar contenidos, entre otros, en registros
            públicos, documentos públicos, gacetas y boletines oficiales y
            sentencias judiciales debidamente ejecutoriadas que no estén
            sometidas a reserva.
          </li>
          <li className="pp-list-elm">
            <strong>Datos Sensibles:</strong> se entiende por datos sensibles,
            aquellos que afectan la intimidad del Titular o cuyo uso indebido
            puede generar su discriminación, tales como aquellos que revelen el
            origen racial o étnico, la orientación política, las convicciones
            religiosas o filosóficas, la pertenencia a sindicatos,
            organizaciones sociales de derechos humanos o que promueva intereses
            de cualquier partido político o que garanticen los derechos y
            garantías de partidos políticos de oposición, así como los datos
            relativos a la salud, a la vida sexual y los datos biométricos.
          </li>
          <li className="pp-list-elm">
            <strong>Encargado:</strong> la persona natural o jurídica, pública o
            privada, que por sí misma o en asocio con otros, realice el
            Tratamiento de datos personales por cuenta del Responsable del
            Tratamiento.
          </li>
          <li className="pp-list-elm">
            <strong>Hábeas Data:</strong> es el derecho fundamental que tiene
            toda persona para conocer, actualizar, rectificar y/o cancelar la
            información y datos personales que de ella se hayan recolectado y/o
            se traten con bases en datos públicos o privados, conforme a lo
            dispuesto en la Ley y demás normas aplicables.
          </li>
          <li className="pp-list-elm">
            <strong>Principios para el tratamiento de datos personales:</strong>{" "}
            son las reglas fundamentales de orden legal y/o jurisprudencial, que
            inspiran y orientan el tratamiento de datos personales, a partir de
            los cuales se determinan acciones y criterios para dar solución a la
            posible colisión entre el derecho a la intimidad, hábeas data y
            protección de datos personales, y el derecho a la información.
          </li>
          <li className="pp-list-elm">
            <strong>Reclamo:</strong> solicitud del Titular o las personas
            autorizadas por este o por la Ley para corregir, actualizar o
            suprimir sus Datos Personales o para revocar la autorización en los
            casos establecidos por la Ley.
          </li>
          <li className="pp-list-elm">
            <strong>Responsable del Tratamiento:</strong> persona natural o
            jurídica, pública o privada, que por sí misma o en asocio con otros,
            decida sobre la base de datos y/o el Tratamiento de los datos.
          </li>
          <li className="pp-list-elm">
            <strong>SIC:</strong> Superintendencia de Industria y Comercio.
          </li>
          <li className="pp-list-elm">
            <strong>Titular:</strong> persona natural cuyos datos personales
            sean objeto de Tratamiento.
          </li>
          <li className="pp-list-elm">
            <strong>Tratamiento:</strong> cualquier operación o conjunto de
            operaciones sobre datos personales, tales como la recolección,
            almacenamiento, uso, circulación o supresión.
          </li>
          <li className="pp-list-elm">
            <strong>Transferencia:</strong> la transferencia de datos tiene
            lugar cuando el Responsable y/o Encargado del tratamiento de datos
            personales, ubicado en Colombia, envía la información o los datos
            personales a un receptor, que a su vez es responsable del
            tratamiento y se encuentra dentro o fuera del país.
          </li>
          <li className="pp-list-elm">
            <strong>Transmisión:</strong> tratamiento de datos personales que
            implica la comunicación de los mismos dentro o fuera del territorio
            de la República de Colombia cuando tenga por objeto la realización
            de un Tratamiento por el Encargado por cuenta del responsable.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          IV. PRINCIPIOS APLICABLES AL TRATAMIENTO DE DATOS PERSONALES
        </h3>
        <p className="pp-txt">
          <strong>CAVCA</strong> aplicará los siguientes principios específicos
          que establecen y constituyen las reglas a seguir en la recolección,
          manejo, uso, tratamiento, almacenamiento e intercambio de Datos
          Personales:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>Principio de Legalidad:</strong> el Tratamiento es una
            actividad reglamentada que debe sujetarse a las disposiciones
            vigentes y aplicables que rigen el Tratamiento de Datos Personales y
            demás derechos fundamentales conexos.
          </li>
          <li className="pp-list-elm">
            <strong>Principio de Finalidad:</strong> el Tratamiento debe
            obedecer a una finalidad legítima de acuerdo con la Constitución y
            la Ley, la cual debe ser informada al Titular.
          </li>
          <li className="pp-list-elm">
            <strong>Principio de Libertad:</strong> el Tratamiento solo puede
            ejercerse con el consentimiento, previo, expreso e informado del
            Titular. Los Datos Personales no podrán ser divulgados sin previa
            autorización o en ausencia de mandato legal, estatutario o judicial
            que releve el consentimiento.
          </li>
          <li className="pp-list-elm">
            <strong>Principio de Veracidad o Calidad:</strong> la información
            sujeta a tratamiento debe ser veraz, completa, exacta, actualizada,
            comprobable y comprensible. Se prohíbe el Tratamiento de datos
            parciales, incompletos, fraccionados o que induzcan a error.
          </li>
          <li className="pp-list-elm">
            <strong>Principio de Transparencia:</strong> en el Tratamiento debe
            garantizarse el derecho del Titular a obtener de CAVCA, en cualquier
            momento y sin restricciones, información acerca de la existencia de
            los datos que le concierne.
          </li>
          <li className="pp-list-elm">
            <strong>Principio de Acceso y Circulación Restringida:</strong> el
            Tratamiento se sujeta a los límites que se derivan de la naturaleza
            de los datos personales, de las disposiciones de la Ley y la
            Constitución, en este sentido, el Tratamiento solo podrá hacerse por
            personas autorizadas por el Titular y/o por las personas previstas
            en la Ley.
          </li>
        </ul>
        <p className="pp-txt">
          Los Datos Personales, salvo la información pública, no podrán estar
          disponibles en Internet u otros medios de divulgación o comunicación
          masiva, salvo que el acceso sea técnicamente controlable para brindar
          un conocimiento restringido solo a los Titulares o terceros
          autorizados conforme la Ley.
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>Principio de Confidencialidad:</strong> todas las personas
            que intervienen en el tratamiento de la información registrada en
            nuestras bases de datos, se comprometen a conservar y mantener de
            manera estrictamente confidencial y no revelarla a terceros todas
            las informaciones personales, comerciales, contables, técnicas,
            comerciales o de cualquier otro tipo suministradas en la ejecución y
            ejercicio de sus funciones.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          V. DATOS PERSONALES OBJETO DE TRATAMIENTO
        </h3>
        <p className="pp-txt">
          Los Datos Personales que <strong>CAVCA</strong> trata por cuenta
          propia o a través de un tercero encargado para ello son los
          siguientes:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">Nombres y Apellidos.</li>
          <li className="pp-list-elm">
            Tipo de documento y número de identificación.
          </li>
          <li className="pp-list-elm">
            Datos de contacto (teléfonos fijos y celulares).
          </li>
          <li className="pp-list-elm">Direcciones físicas de contacto.</li>
          <li className="pp-list-elm">Correos electrónicos de contacto.</li>
          <li className="pp-list-elm">
            Datos exigidos por la Superintendencia Financiera, su Circular
            Básica Jurídica y en el SARLAFT.
          </li>
          <li className="pp-list-elm">
            Datos demográficos como edad, fecha de nacimiento, sexo, idioma,
            lugar de residencia, ocupación.
          </li>
          <li className="pp-list-elm">Intereses y preferencias.</li>
          <li className="pp-list-elm">
            Datos característicos de los riesgos que puedan tener interés
            asegurable como placa de automóviles o dirección de los inmuebles,
            entre otros.
          </li>
          <li className="pp-list-elm">
            Información sobre seguros cotizados y oportunidades para presentar
            nuestras ofertas.
          </li>
          <li className="pp-list-elm">
            Información requerida por los representantes de las áreas de ventas.
          </li>
        </ul>
        <p className="pp-txt">
          Debe destacarse que <strong>CAVCA</strong>, con el fin de cumplir con
          las obligaciones impuestas por la Superintendencia Financiera de
          Colombia, en lo referente al Sistema de Administración del Riesgo de
          Lavado de Activos y Financiación del Terrorismo – SARLAFT, solicitará
          a los Titulares un (1) Dato Personal Sensible de tipo Biométrico
          correspondiente a la huella dactilar.
        </p>
        <p className="pp-txt">
          Los datos de los Titulares se obtienen a través de diferentes canales,
          los cuales se señalan a continuación:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>CAVCA</strong> recopila Datos Personales Públicos de
            potenciales clientes a través de registros o bases de datos que por
            Ley son de naturaleza pública (RUT, RUES, Bases de Datos de Cámaras
            de Comercio, Bases de Datos de las Alcaldías Municipales, Directorio
            Telefónico, RUNT, SIMIT, RUP, Garantías Mobiliarias, Registro de
            Instrumentos Públicos o Bases de Datos de Carácter Privado en la
            cual los Titulares han autorizado para la presentación de oferta de
            bienes y servicios adicionales). Teniendo en cuenta que, de acuerdo
            con la legislación aplicable, la información contenida en registros
            públicos adquiere naturaleza de Dato Público, <strong>CAVCA</strong>{" "}
            no requiere autorización para su Tratamiento.
          </li>
          <li className="pp-list-elm">
            También <strong>CAVCA</strong> obtiene Datos Personales de clientes
            y potenciales clientes a través los formatos o consultas que se
            realizan en el establecimiento de la relación de los Titulares con{" "}
            <strong>CAVCA</strong>, por ejemplo: al enviar una consulta sobre
            alguno de los servicios que proporciona <strong>CAVCA</strong>, al
            realizar una cotización por cualquiera de nuestros medios de
            contacto, incluyendo la página web, vía telefónica, correo
            electrónico, herramientas de comunicación instantánea como chat y
            WhatsApp, al aplicar a posiciones dentro de la compañía, con la
            adquisición de productos y servicios, entre otros. Frente a los
            datos que se recolectan, <strong>CAVCA</strong> obtendrá la
            respectiva autorización e informará a los Titulares sobre los datos
            que en su momento se recolectan para realizar el Tratamiento.
          </li>
          <li className="pp-list-elm">
            <strong>CAVCA</strong> deja expresa constancia que la actividad
            comercial desarrollada por esta es una gestión de representación
            propia, que no involucra a ninguna de las aseguradoras con las
            cuales <strong>CAVCA</strong>
            tiene vínculos comerciales, es por esto que en ningún caso estas
            revelan o entregan Datos Personales de sus clientes a{" "}
            <strong>CAVCA</strong>.
          </li>
          <li className="pp-list-elm">
            <strong>CAVCA</strong> recolecta y almacena Datos Personales de
            aspirantes a cargos y vacantes en los procesos de selección, durante
            toda la vigencia de la relación laboral establecida con personas
            naturales y para la contratación de servicios o productos con
            terceros proveedores.
          </li>
          <li className="pp-list-elm">
            Así mismo, <strong>CAVCA</strong> da Tratamiento a Datos Personales
            obtenidos en actividades derivadas de su relación con quienes hayan
            sido accionistas y/o administradores de la sociedad.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          VI. FINALIDADES Y USOS DE LOS DATOS PERSONALES
        </h3>
        <p className="pp-txt">
          Los Datos Personales podrán ser utilizados por <strong>CAVCA</strong>{" "}
          o quien este designe como Encargado, por el tiempo que exista la
          relación con el Titular, para las siguientes finalidades:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            Ofrecer al Titular la adquisición de productos y servicios que{" "}
            <strong>CAVCA</strong> tiene para este.
          </li>
          <li className="pp-list-elm">
            Conocer del Titular los hábitos de consumo, gustos y preferencias
            con la finalidad de ofrecerle aquellos productos que se adecuen a
            ellos, enviarle promociones, publicidad, cupones de descuentos de
            nuestros productos y servicios, así como de aquellos de las empresas
            con las que <strong>CAVCA</strong> tiene relación corporativa.
          </li>
          <li className="pp-list-elm">
            Establecer un canal de comunicación con el Titular a fin de
            informarle de novedades u ofertas respecto a los productos y
            servicios ofrecidos por <strong>CAVCA</strong>.
          </li>
          <li className="pp-list-elm">
            Elaborar estudios de mercadotecnia, segmentación de mercado y
            estadísticas.
          </li>
          <li className="pp-list-elm">
            Remitir invitaciones al Titular para participar en concursos o
            sorteos y actividades.
          </li>
          <li className="pp-list-elm">
            Operar con eficacia y proporcionar al Titular las mejores
            experiencias con los servicios de <strong>CAVCA</strong>.
          </li>
          <li className="pp-list-elm">
            Cumplir con todas de las obligaciones y para que ejerza los derechos
            que se derivan de su calidad de Sociedad Comercial y en general de
            las actividades propias de su objeto social principal y conexo.
          </li>
          <li className="pp-list-elm">
            Enviar información referente a documentos de cobro y de pago como
            facturas.
          </li>
          <li className="pp-list-elm">
            Crear y gestionar Bases de Datos para el seguimiento y
            acompañamiento del usuario en el consumo de los diferentes productos
            que comercializa la sociedad, calidad, actividades de capacitación,
            fines de investigación, desarrollo de nuevos productos, así como el
            seguimiento de quejas, reclamos, siniestros, entre otros.
          </li>
          <li className="pp-list-elm">
            Información sobre seguros cotizados y oportunidades para presentar
            nuestras ofertas.
          </li>
          <li className="pp-list-elm">
            Dar cumplimiento a las políticas internas de la compañía, que de
            acuerdo con las exigencias de las normativas vigentes. La compañía
            podrá solicitar datos personales para la selección de personal,
            estudio de hojas de vida, verificación de datos suministrados por el
            candidato, verificación de los contactos de referencias personales,
            familiares y/o comerciales, datos de localización, exámenes de salud
            de ingreso y/o egreso de la compañía, realización de pruebas
            escritas u orales de selección, entrevistas, suministro de datos
            personales para las afiliaciones al Sistema de Seguridad Social
            Integral, datos de los familiares del personal contratado bajo
            cualquier modalidad por la compañía, cuentas bancarias para pagos de
            salarios y honorarios y en general la aceptación de los
            procedimientos internos de selección, ingreso, salud ocupacional y
            contratación como empleado, colaborador o contratista en{" "}
            <strong>CAVCA</strong>, o cualquier filial y/o división de negocio o
            cualquiera de las empresas que llegaran a ser vinculadas o
            asociadas, y todo lo relacionado con el tratamiento de datos
            personales enmarcados en el cumplimiento de las relaciones,
            objetivos, obligaciones, derechos y demás elementos originados en el
            tipo de vinculación contractual en el marco laboral, civil o
            comercial.
          </li>
          <li className="pp-list-elm">
            Contactar a los clientes a través de medios electrónicos,
            telefónicos, SMS, chat, correo electrónico y demás medios
            considerados electrónicos, físicos y/o personales.
          </li>
          <li className="pp-list-elm">
            Facilitar la correcta ejecución de las compras y los servicios
            contratados.
          </li>
          <li className="pp-list-elm">
            Dar cumplimiento a las obligaciones que <strong>CAVCA</strong> tenga
            con sus clientes, empleados, proveedores así como con las
            autoridades judiciales o administrativas.
          </li>
        </ul>
        <p className="pp-txt">
          En estos casos, <strong>CAVCA</strong> dará estricto cumplimiento a
          las normas que se han dispuesto para la recolección, almacenamiento,
          actualización, uso, circulación, transferencia y supresión de Datos
          Personales.
        </p>
        <p className="pp-txt">
          <strong>CAVCA</strong> y sus filiales afiliadas, subsidiarias o
          empresas parte del grupo empresarial, podrán compartir información
          entre ellas, teniendo presente que cada una de estas deberá definir
          sus finalidades en atención a sus propias necesidades y procurando
          respeto a los derechos de los Titulares y a los principios y valores
          establecidos en la presente Política.
        </p>
        <p className="pp-txt">
          En este sentido, las compañías afiliadas o que hacen parte del grupo,
          podrán compartir información de los Titulares y cada una de las
          empresas, en su calidad de Responsable deberá velar porque existan
          términos y condiciones que garanticen una adecuada entrega,
          almacenamiento y manejo de esta información.
        </p>
        <h3 className="pp-subtitle-small">VII. DERECHOS DE LOS TITULARES</h3>
        <p className="pp-txt">
          <strong>CAVCA</strong> en calidad de Responsable y/o Encargado de los
          Datos Personales, garantizará en todo momento a los Titulares los
          siguientes derechos:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            Conocer, actualizar y rectificar sus datos personales frente a
            <strong>CAVCA</strong>. Este derecho se podrá ejercer, entre otros
            frente a datos parciales, inexactos, incompletos, fraccionados, que
            induzcan a error o aquellos cuyo tratamiento esté expresamente
            prohibido o no haya sido autorizado.
          </li>
          <li className="pp-list-elm">
            Solicitar prueba de la autorización otorgada, salvo cuando
            expresamente se exceptúa en la ley como requisito para el
            Tratamiento, de conformidad con lo previsto en el artículo 10 de la
            Ley 1581 de 2012 (o en su defecto con las normas que la reglamenten,
            adicionen, ejecuten, complementen, modifiquen, supriman o deroguen)
            o cuando se haya presentado la continuidad del tratamiento acorde
            con el artículo 10 numeral 4° del Decreto 1377 de 2013.
          </li>
          <li className="pp-list-elm">
            Ser informado por <strong>CAVCA</strong>, previa solicitud, acerca
            de la existencia de datos que le conciernan y el uso que se le ha
            dado a sus datos personales.
          </li>
          <li className="pp-list-elm">
            Ser informado por parte de los Encargados del Tratamiento, previa
            solicitud, respecto del uso que le ha dado a sus datos personales.
          </li>
          <li className="pp-list-elm">
            Presentar ante la Superintendencia de Industria y Comercio quejas
            por infracciones a lo dispuesto en la Ley 1581 de 2012, Decreto 1377
            de 2013 y las demás normas que la modifiquen, adicionen o
            complementen.
          </li>
          <li className="pp-list-elm">
            Revocar la autorización y/o solicitar la supresión del dato cuando
            en el Tratamiento no se respeten los principios, derechos y
            garantías constitucionales y legales. La revocatoria y/o supresión
            procederá cuando la Superintendencia de Industria y Comercio haya
            determinado que en el tratamiento el responsable o encargado han
            incurrido en conductas contrarias a la Ley y a la Constitución.
          </li>
          <li className="pp-list-elm">
            Acceder en forma gratuita a sus datos personales que hayan sido
            objeto de Tratamiento.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          VIII. DERECHOS DE LOS NIÑOS, NIÑAS Y ADOLESCENTES
        </h3>
        <p className="pp-txt">
          <strong>CAVCA</strong> en todo momento garantizará que, en el
          Tratamiento de Datos Personales de niños, niñas y adolescentes,
          responderá y mantendrá como principio superior el respeto a los
          derechos prevalentes de los niños, niñas y adolescentes.
        </p>
        <p className="pp-txt">
          El Tratamiento de Datos Personales de niños, niñas y adolescentes no
          es posible, excepto cuando se trate de datos de naturaleza pública de
          conformidad con lo establecido en el artículo 7 de la Ley 1581 de 2012
          y cuando dicho Tratamiento cumpla con los siguientes parámetros y
          requisitos:
        </p>
        <ol className="pp-list">
          <li className="pp-list-elm">
            Responda y respete el interés superior de los niños, niñas y
            adolescentes. <strong>CAVCA</strong> comercializa productos cuyos
            beneficiarios son niños, niñas y adolescentes. En consecuencia, la
            información o datos sensibles de la adquisición de productos cuyos
            beneficiarios sean niños, niñas y adolescentes se encuentren en
            poder de <strong>CAVCA</strong> solo serán utilizados con la
            finalidad mencionada en el presente párrafo.
          </li>
          <li className="pp-list-elm">
            Se asegure el respeto de sus derechos fundamentales.
          </li>
        </ol>
        <p className="pp-txt">
          Cumplidos los anteriores requisitos, el representante legal del niño,
          niña o adolescente otorgará la autorización previo ejercicio del menor
          de su derecho a ser escuchado, opinión que será valorada teniendo en
          cuenta la madurez, autonomía y capacidad para entender el asunto.
        </p>
        <p className="pp-txt">
          Todo Responsable y Encargado involucrado en el Tratamiento de los
          datos personales de niños, niñas y adolescentes, deberá velar por el
          uso adecuado de los mismos. Para este fin deberán aplicarse los
          principios y obligaciones establecidos en la Ley 1581 de 2012 y el
          Decreto 1377 de 2013.
        </p>
        <h3 className="pp-subtitle-small">
          IX. OBLIGACIONES DEL RESPONSABLE DEL TRATAMIENTO
        </h3>
        <p className="pp-txt">
          <strong>CAVCA</strong> tendrá los siguientes deberes frente a los
          Titulares de los Datos Personales y hará todo el esfuerzo razonable
          para que los mismos sean garantizados:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio
            de derechos de los Titulares, en especial el derecho de Hábeas Data.
          </li>
          <li className="pp-list-elm">
            Informar al Titular, sobre la finalidad de la recolección y los
            derechos que le asisten por virtud de la Autorización otorgada.
          </li>
          <li className="pp-list-elm">
            Conservar la información bajo las condiciones de seguridad idóneas
            para impedir su adulteración, pérdida, consulta, uso o acceso no
            autorizado o fraudulento.
          </li>
          <li className="pp-list-elm">
            Rectificar la información cuando sea incorrecta.
          </li>
          <li className="pp-list-elm">
            Tramitar las consultas y reclamos dentro de los términos de la ley.
          </li>
          <li className="pp-list-elm">
            Actualizar la información y adoptar las medidas para que la
            información se mantenga actualizada.
          </li>
          <li className="pp-list-elm">
            Adoptar un manual interno de políticas y procedimientos para
            garantizar el adecuado cumplimiento de la ley de Datos Personales y
            en especial, para la atención de consultas y reclamos.
          </li>
          <li className="pp-list-elm">
            Observar los principios de veracidad, calidad, seguridad y
            confidencialidad en los términos establecidos en la presente
            Política.
          </li>
          <li className="pp-list-elm">
            Suministrar al encargado del tratamiento únicamente los Datos
            Personales necesarios para el cumplimiento de las finalidades
            previstas en la presente Política.
          </li>
          <li className="pp-list-elm">
            Comunicar al Encargado del Tratamiento todas las novedades respecto
            de los datos que previamente le haya suministrado.
          </li>
          <li className="pp-list-elm">
            Exigir al encargado del tratamiento, en todo momento, respecto a las
            condiciones de seguridad y privacidad de la información del Titular.
          </li>
          <li className="pp-list-elm">
            Informar al Encargado del Tratamiento cuando determinada información
            se encuentre en discusión por parte del Titular.
          </li>
          <li className="pp-list-elm">
            Cumplir las instrucciones y requerimientos que imparta la SIC.
          </li>
          <li className="pp-list-elm">
            Informar a la SIC, cuando se presenten violaciones a los códigos de
            seguridad y existan riesgos en la administración de la información
            de los Titulares.
          </li>
          <li className="pp-list-elm">
            Cumplir con las demás obligaciones establecidas en la ley 1581 de
            2012, en el decreto 1377 de 2012 o en su defecto con las normas que
            la reglamenten, adicionen, ejecuten, complementen, modifiquen,
            supriman o deroguen.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          X. OBLIGACIONES DEL ENCARGADO DEL TRATAMIENTO
        </h3>
        <p className="pp-txt">
          Tanto CAVCA como los contratistas que realicen tratamiento de datos en
          calidad de Encargados, deberán cumplir los siguientes deberes y hará
          todo su esfuerzo razonable para que los mismos sean garantizados:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            Garantizar al Titular en todo tiempo, el pleno y efectivo ejercicio
            del derecho de Habeas Data.
          </li>
          <li className="pp-list-elm">
            Permitir el acceso a la información únicamente al Titular, las
            personas autorizadas por este, o facultadas por la ley para tal
            efecto.
          </li>
          <li className="pp-list-elm">
            Tramitar las consultas y los reclamos formulados por los Titulares
            en los términos señalados en la presente Política.
          </li>
          <li className="pp-list-elm">
            Actualizar la información reportada por los Responsables del
            Tratamiento.
          </li>
          <li className="pp-list-elm">
            Realizar oportunamente la actualización, rectificación o supresión
            de los datos.
          </li>
          <li className="pp-list-elm">
            Abstenerse de circular información que esté siendo controvertida por
            el Titular y cuyo bloqueo haya sido ordenado por la SIC.
          </li>
          <li className="pp-list-elm">
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento.
          </li>
          <li className="pp-list-elm">
            En general cumplir todas las obligaciones previstas en la presente
            Política y normas vigentes aplicables.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">XI. AUTORIZACIÓN Y CONSENTIMIENTO</h3>
        <p className="pp-txt">
          La recolección, almacenamiento, uso, circulación o supresión de Datos
          Personales por parte de <strong>CAVCA</strong> requiere del
          consentimiento libre, previo, expreso e informado del Titular de los
          mismos. Así como también puede corresponder a información de carácter
          público o al cumplimiento de los deberes legales y contractuales que
          exigen la conservación de determinada información personal.
        </p>
        <p className="pp-txt">
          Mediante la presente Política, <strong>CAVCA</strong> pone a
          disposición de los Titulares de los Datos Personales, la siguiente
          información, con el fin de que estos expresen su consentimiento:
        </p>
        <ol className="pp-list">
          <li className="pp-list-elm">
            El Tratamiento al cual serán sometidos sus Datos Personales y la
            finalidad del mismo.
          </li>
          <li className="pp-list-elm">
            El carácter facultativo de la respuesta a las preguntas que le sean
            hechas, cuando estas versen sobre Datos Sensibles o sobre los datos
            de niños, niñas y adolescentes.
          </li>
          <li className="pp-list-elm">
            Los derechos que le asisten como titular.
          </li>
          <li className="pp-list-elm">
            La identificación, dirección física o electrónica y el teléfono del
            Responsable del Tratamiento.
          </li>
        </ol>
        <p className="pp-txt">
          En todo caso, la recolección de Datos Personales se limita a aquellos
          Datos Personales que son pertinentes y adecuados para la finalidad
          para la cual son recolectados o requeridos conforme a la normatividad
          vigente.
        </p>
        <p className="pp-txt">
          La Autorización puede constar en un documento físico, electrónico,
          grabación, mensaje de datos, Internet, Sitios Web, en cualquier otro
          formato que permita garantizar su posterior consulta o mediante un
          mecanismo técnico o tecnológico idóneo, que permita manifestar u
          obtener el consentimiento, mediante el cual se pueda concluir de
          manera inequívoca, que de no haberse surtido una conducta del titular,
          los datos nunca hubieren sido capturados y almacenados en la Base de
          Datos.
        </p>
        <p className="pp-txt">
          <strong>CAVCA</strong> utilizará los mecanismos con que cuenta
          actualmente e implementará y adoptará las acciones tendientes y
          necesarias para mantener registros o mecanismos técnicos o
          tecnológicos idóneos de cuándo y cómo obtuvo Autorización por parte de
          los Titulares de Datos Personales para el Tratamiento de los mismos.
          Para dar cumplimiento a lo anterior, se podrán establecer archivos
          físicos o repositorios electrónicos realizados de manera directa o a
          través de terceros contratados para tal fin.
        </p>
        <h3 className="pp-subtitle-small">
          XII. POLÍTICAS DE TRATAMIENTO DE DATOS PERSONALES
        </h3>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>CAVCA</strong> recabará y tratará, directamente o a través
            de un tercero designado, de manera lícita y conforme a las
            disposiciones establecidas en la normatividad aplicable los Datos
            Personales.
          </li>
          <li className="pp-list-elm">
            Para el Tratamiento de Datos Personales <strong>CAVCA</strong>{" "}
            cumplirá con los siguientes principios rectores de la protección de
            datos: (i) legalidad; (ii) finalidad; (iii) libertad; (iv)
            veracidad; (v) transparencia; (vi) acceso y circulación restringida;
            (vii) seguridad; y (viii) confidencialidad.
          </li>
          <li className="pp-list-elm">
            Bajo ninguna circunstancia <strong>CAVCA</strong> obtendrá Datos
            Personales a través de medios engañosos o fraudulentos.
          </li>
          <li className="pp-list-elm">
            El manejo de Datos Personales que realice <strong>CAVCA</strong> o
            terceros por ella designados, será transparente para que su Titular
            pueda comprobar la información que haya proporcionado. Para el
            tratamiento de datos Personales, se tendrán en cuenta los siguientes
            aspectos:
            <ol className="pp-list">
              <li className="pp-list-elm">
                Los Datos Personales únicamente serán tratados conforme a la
                finalidad para la cual fueron recopilados.
              </li>
              <li className="pp-list-elm">
                En caso de uso por terceros, se informará la identidad de los
                terceros, los fines de su uso y el tipo de Datos Personales a
                ser proporcionados a dichos terceros.
              </li>
              <li className="pp-list-elm">
                El periodo autorizado de uso y proceso de eliminación de Datos
                Personales.
              </li>
              <li className="pp-list-elm">
                Se comunicará a los titulares de los Datos Personales los
                derechos con que cuentan y la información relevante para que
                pueda ejercer dichos derechos.
              </li>
            </ol>
          </li>
          <li className="pp-list-elm">
            La información y Datos Personales que <strong>CAVCA</strong> reciba,
            debe ser tratada de manera confidencial y la utilizará
            exclusivamente para los fines y objetivos descritos en el
            correspondiente Aviso de Privacidad, en la autorización para el
            tratamiento de los datos y para los fines permitidos por la ley.
          </li>
          <li className="pp-list-elm">
            Salvo los casos de excepción previstos por la ley aplicable, como lo
            es la información contenida en registros públicos,{" "}
            <strong>CAVCA</strong> no tratará Datos Personales sin previa
            Autorización de su Titular, la cual deberá ser otorgada con las
            formalidades previstas por las leyes aplicables.
          </li>
          <li className="pp-list-elm">
            El consentimiento del Titular puede ser revocado en cualquier
            momento. Para la revocación del consentimiento,{" "}
            <strong>CAVCA</strong> informará al Titular de los datos los
            mecanismos y procedimientos para ello mediante la presente Política.
          </li>
          <li className="pp-list-elm">
            De conformidad con el artículo 10 de la Ley 1581 de 2012, no será
            necesario el consentimiento para el tratamiento de Datos Personales,
            cuando:
            <ol className="pp-list">
              <li className="pp-list-elm">
                La información sea requerida por una entidad pública o
                administrativa en ejercicio de sus funciones legales o por orden
                judicial.
              </li>
              <li className="pp-list-elm">
                Se traten de datos de naturaleza pública.
              </li>
              <li className="pp-list-elm">
                En caso de urgencia médica o sanitaria.
              </li>
              <li className="pp-list-elm">
                Para el Tratamiento de información autorizado por la ley para
                fines científicos, estadísticos o históricos.
              </li>
              <li className="pp-list-elm">
                En el caso de datos relacionados con el Registro Civil de las
                Personas.
              </li>
            </ol>
          </li>
          <li className="pp-list-elm">
            <strong>CAVCA</strong> informará a los Titulares de Datos
            Personales, la información que se recaba de ellos y con que fines.
          </li>
          <li className="pp-list-elm">
            Los Avisos de Privacidad deberán caracterizarse por ser sencillos,
            con información necesaria, expresados en lenguaje claro y
            comprensible y con una estructura y diseño que facilite su
            entendimiento.
          </li>
          <li className="pp-list-elm">
            Para la difusión de Avisos de Privacidad, <strong>CAVCA</strong>{" "}
            podrá valerse de formatos físicos, electrónicos, medios verbales o
            cualquier otra tecnología, siempre y cuando garantice y cumpla con
            el deber de informar al Titular en términos de la normativa
            aplicable y obtenga la autorización debida por parte del Titular.
          </li>
          <li className="pp-list-elm">
            <strong>CAVCA</strong> contará con una Política de Privacidad
            aplicable a su página web a la cual se le aplicarán los términos
            generales del presente documento.
          </li>
          <li className="pp-list-elm">
            Cualquier Titular podrá ejercer los derechos establecidos en la
            presente Política y en la Ley.
          </li>
          <li className="pp-list-elm">
            Para el ejercicio de sus derechos los Titulares, tendrán a su
            disposición los medios de comunicación adecuados. En este sentido,
            dentro de <strong>CAVCA</strong> existe un área de servicio al
            cliente, encargada de velar por el cumplimiento de la presente
            Política, al cual atenderá de las consultas y reclamos de los
            Titulares de los datos, en ejercicio de sus derechos.
          </li>
          <li className="pp-list-elm">
            <strong>CAVCA</strong> realizará la transmisión de los Datos
            Personales a terceros proveedores con los cuales tenga suscritos
            contratos para la administración de Datos Personales y que
            garanticen la confidencialidad y seguridad de los datos. De la misma
            manera, los datos personales serán transferidos a empresas del grupo
            corporativo del cual haga parte <strong>CAVCA</strong> y únicamente
            para que se realice el tratamiento según las finalidades autorizadas
            por los Titulares. Cualquier transferencia de datos será informada a
            los Titulares de los Datos Personales dentro del Aviso de
            Privacidad. Las transmisiones de datos también serán informadas,
            salvo que se suscriban contratos de transmisión de datos en los
            términos del artículo 25 del Decreto 1377 de 2013.
          </li>
          <li className="pp-list-elm">
            La entrega de los Datos Personales será gratuita, debiendo cubrir el
            Titular únicamente los gastos justificados relativos al envío o el
            costo de reproducción en copias u otros formatos, cuando el Titular
            o persona autorizada conforme a la ley los solicite.
          </li>
          <li className="pp-list-elm">
            La SIC y su delegatura de Protección de Datos son la entidad
            encargada de vigilar y verificar el cumplimiento de las
            disposiciones de la normativa aplicable a la protección y manejo de
            Datos Personales, así como de emitir criterios y recomendaciones
            para efecto de su funcionamiento y operación.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">
          XIII. PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS DEL TITULAR
        </h3>
        <p className="pp-txt">
          Para efectos de que los Titulares de la información personal puedan
          realizar peticiones, consultas y reclamos, conocer, actualizar,
          rectificar, suprimir información y revocar la autorización concedida o
          solicitar prueba de la misma y en general ejercer sus derechos,{" "}
          <strong>CAVCA</strong>
          ha habilitado los siguientes medios:
        </p>
        <ul className="pp-list">
          <li className="pp-list-elm">
            Correo electrónico: tratamiento.datos@cavca.com.co
          </li>
          <li className="pp-list-elm">
            Por medio escrito en la siguiente dirección: Carrera 22 # 143 – 40
          </li>
        </ul>
        <p className="pp-txt">
          El Titular deberá indicar su nombre completo y número de
          identificación, así como la dirección física o correo electrónico en
          que recibirá la respuesta.
        </p>
        <h3 className="pp-subtitle-small">
          VÍA DE PRESENTACIÓN Y PROCEDIMIENTO
        </h3>
        <ul className="pp-list">
          <li className="pp-list-elm">
            <strong>Consultas:</strong> Los Titulares o sus causahabientes
            podrán consultar la información personal del Titular que repose en
            <strong>CAVCA</strong> y a su vez <strong>CAVCA</strong> deberá
            suministrar a estos toda la información contenida en el registro
            individual o que esté vinculada con la identificación del Titular.
          </li>
          <li className="pp-list-elm">
            <strong>Reclamos:</strong> En caso de que los Titulares de Datos
            Personales tratados por <strong>CAVCA</strong> o sus causahabientes
            consideren que la información contenida en las Bases de Datos de{" "}
            <strong>CAVCA</strong> deben ser objeto de corrección, actualización
            o supresión o cuando adviertan el presunto incumplimiento de
            cualquiera de los deberes manifestados en las normas aplicables
            podrán presentar un reclamo en los siguientes términos:
            <ol className="pp-list">
              <li className="pp-list-elm">
                El reclamo se formulará mediante solicitud dirigida a{" "}
                <strong>CAVCA</strong>, con la identificación del Titular, la
                descripción de los hechos que dan lugar al reclamo, la dirección
                y acompañando los documentos que se quiera hacer valer.
              </li>
              <li className="pp-list-elm">
                Una vez recibido el reclamo completo, se incluirá en la base de
                datos de <strong>CAVCA</strong> una leyenda que diga “reclamo en
                trámite” y el motivo del mismo, en un término no mayor a dos
                (02) días hábiles. Dicha leyenda deberá mantenerse hasta que el
                reclamo sea decidido.
              </li>
              <li className="pp-list-elm">
                <strong>CAVCA</strong> atenderá dicha solicitud en el término
                máximo permitido por la ley contado a partir del día siguiente a
                la fecha de recibo completo del reclamo.
              </li>
              <li className="pp-list-elm">
                Previo a dar trámite a cualquier reclamo, se verificará la
                identidad del reclamante, quién debe ser el Titular, su
                causahabiente o su representante. Si el reclamante no es ninguna
                de las personas indicadas anteriormente, no se tramitará el
                reclamo.
              </li>
              <li className="pp-list-elm">
                Un reclamo completo debe contener, por lo menos:
                <ul className="pp-list">
                  <li className="pp-list-elm">
                    Identificación del Titular y del reclamante (en caso de que
                    no sea el mismo Titular).
                  </li>
                  <li className="pp-list-elm">
                    Descripción de los hechos que dan lugar al reclamo.
                  </li>
                  <li className="pp-list-elm">
                    Dirección física o electrónica de notificación.
                  </li>
                  <li className="pp-list-elm">
                    Documentos y anexos que se pretendan hacer valer en el curso
                    del reclamo.
                  </li>
                  <li className="pp-list-elm">
                    Petición u objeto del reclamo.
                  </li>
                </ul>
              </li>
              <li className="pp-list-elm">
                Cuando se solicite la supresión de datos, la misma no podrá
                realizarse cuando:
                <ul className="pp-list">
                  <li className="pp-list-elm">
                    Sea una obligación legal o contractual conservar dichos
                    datos.
                  </li>
                  <li className="pp-list-elm">
                    Conservar los datos sea imprescindible para salvaguardar los
                    intereses del Titular o el Interés Público.
                  </li>
                </ul>
              </li>
              <li className="pp-list-elm">
                Cuando se solicite la revocatoria de la Autorización, es preciso
                que el interesado invoque las razones por las cuales considera
                el presunto incumplimiento de cualquiera de los deberes
                contenidos en la presente Política o cuando la información
                contenida en la Base de Datos deba ser objeto de corrección o
                actualización. Así mismo debe informar con precisión si la
                revocatoria es total o parcial. La revocatoria de la
                Autorización es parcial cuando el interesado manifiesta que
                desea revocar el Tratamiento de sus datos personales para
                ciertas finalidades o datos específicos. La revocatoria de la
                autorización será total cuando se solicita que se detenga el
                Tratamiento de Datos Personales para todas las finalidades y
                datos autorizados.
              </li>
            </ol>
          </li>
          <li className="pp-list-elm">
            <strong>Quejas ante la SIC:</strong> De acuerdo a lo previsto en el
            artículo 16 de la ley 1581 del 2012, el Titular o causahabiente solo
            podrá elevar queja ante la Superintendencia de Industria y Comercio,
            una vez agotado el trámite de consulta o reclamo dispuesto en la
            presente Política ante CAVCA.
          </li>
        </ul>
        <h3 className="pp-subtitle-small">XIV. CAMBIOS DE LA POLÍTICA</h3>
        <p className="pp-txt">
          CAVCA se reserva la facultad de revisar y/o modificar en cualquier
          momento esta Política de Tratamiento de Datos Personales. CAVCA
          publicará en su sitio web cualquier cambio en esta Política.
        </p>
        <p className="pp-txt">
          Cuando se realicen modificaciones sustanciales a esta Política, se
          comunicará este hecho a los Titulares de la información mediante el
          envío de un aviso al correo electrónico que hayan registrado, antes de
          o a más tardar al momento de implementarlos, informándoles que podrán
          consultar la nueva política en el portal. En dicho aviso se indicará
          la fecha a partir de la cual regirá la nueva política. Cuando el
          cambio se refiera a las finalidades del tratamiento, se solicitará de
          los Titulares una nueva autorización para aplicar las mismas.
        </p>
        <p className="pp-txt">
          <strong>Actualizaciones de la Política:</strong> Cualquier cambio
          sustancial en el contenido de la presente Política, relacionado con la
          identificación del Responsable y a la finalidad del Tratamiento de los
          Datos Personales, los cuales puedan afectar el contenido de la
          Autorización, serán comunicados a los Titulares a través de este mismo
          medio, y en caso de ser sustanciales se informarán de manera oportuna
          anteponiendo siempre los derechos de los Titulares, a través de este
          mismo medio.
        </p>
        <h3 className="pp-subtitle-small">
          XV. REQUERIMIENTOS DE LAS AUTORIDADES
        </h3>
        <p className="pp-txt">
          <strong>CAVCA</strong> cooperará con las autoridades competentes para
          garantizar el cumplimiento de las leyes, en materia de protección de
          la propiedad industrial, derechos de autor, prevención del fraude y
          otras materias.
        </p>
        <p className="pp-txt">
          Los Titulares autorizan expresamente a <strong>CAVCA</strong> a
          suministrar cualquier información personal sobre ellos, con la
          finalidad de dar cumplimiento a cualquier requerimiento de autoridad
          competente, y con el fin de cooperar con las autoridades competentes
          en la medida en que discrecionalmente lo entendamos necesario y
          adecuado en relación con cualquier investigación de un ilícito,
          infracción de las normas del Estatuto del Consumidor, infracción de
          derechos de propiedad intelectual o industrial, u otra actividad que
          sea ilegal o que pueda exponer a <strong>CAVCA</strong>, a cualquier
          responsabilidad legal.
        </p>
        <h3 className="pp-subtitle-small">
          XVI. LEGISLACIÓN NACIONAL VIGENTE Y JURISDICCIÓN APLICABLE
        </h3>
        <p className="pp-txt">
          Esta Política se rige por las leyes de la República de Colombia y por
          lo dispuesto en la ley 1581 de 2012, su decreto reglamentario y demás
          normas que las modifiquen, deroguen o sustituyan.
        </p>
        <h3 className="pp-subtitle-small">XVII. VIGENCIA Y PUBLICACIÓN</h3>
        <p className="pp-txt">
          La presente Política entrará en vigencia a partir del 18 de junio de
          2019.
        </p>
        <p className="pp-txt">
          Las Bases de Datos tendrán una vigencia igual al periodo en que se
          mantenga la finalidad o finalidades del Tratamiento en cada Base de
          Datos, o el periodo de vigencia que señale una causa legal,
          contractual o jurisprudencial de manera específica.
        </p>
        <p className="pp-txt">
          La política de Tratamiento de Datos Personales de CAVCA será
          comunicada a los Titulares y publicados en la página web{" "}
          <a className="pp-anchor" href="http://www.cavcaseguros.co">
            https://www.cavcaseguros.co
          </a>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicySection;
