import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./ProductDetailFirstSection.css";

//Component
import ProductDetailButtonInfoComponent from "../ProductDetailButtonInfoComponent/ProductDetailButtonInfoComponent";

const ProductDetailFirstSection = ({ sectionData }) => {
  const elements = [];

  sectionData &&
    sectionData.fsd_has_icons &&
    sectionData.fsd_icons_data.map((icon) => {
      return elements.push(<ProductDetailButtonInfoComponent iconData={icon} />);
    });

  return (
    <section className="product-detail-first-section">
      <div className="product-detail-first-subcontainer">
        {sectionData && (
          <>
            <div className="pdf-first-info-container">
              <h1 className="pdf-fi-title">{sectionData.fsd_title}</h1>
              <div className="pdf-fi-content">
                {sectionData.fsd_parragraph.map((p, i) => {
                  return (
                    <p className="pdf-fi-txt-elm" key={i}>
                      {p.fsd_p_txt}
                    </p>
                  );
                })}
              </div>
              {sectionData.fsd_has_list && (
                <ul className="pdf-fi-list-container">
                  <li className="pdf-fi-list-elm">
                    Seguro todo riesgo: en caso de una pérdida total por hurto o
                    daño de tu auto, el seguro todo riesgo responderá por el
                    saldo adeudado a la fecha en la entidad financiera y el
                    excedente se entregará al propietario del vehículo. Además,
                    tendrás cobertura para tu vehículo por pérdidas parciales
                    bien sea hurto o daño, (deducible), cobertura por daños y/o
                    bienes a terceros, así como asistencias gratuitas (dependen
                    de cada aseguradora), las cuales podrás utilizar cuando lo
                    requieras.
                  </li>
                  <li className="pdf-fi-list-elm">
                    Seguro de vida deudor: en caso de fallecimiento o
                    incapacidad total y permanente del titular del crédito, la
                    aseguradora responderá por el saldo adeudado a la fecha en
                    la entidad financiera y el excedente se entregará a los
                    beneficiarios de ley del titular, dejando el vehículo libre
                    de crédito.
                  </li>
                </ul>
              )}
            </div>
            {sectionData.fsd_has_icons && (
              <div className="pdf-second-info-container">
                {sectionData.fsd_has_title_icons && (
                  <h1 className="pdf-si-title">
                    {sectionData.fsd_title_icons}
                  </h1>
                )}
                <div className={`pdf-si-btns-container ${sectionData.fsd_num_icon === 4 ? "four-col" : ""}`}>
                  {sectionData.fsd_icons_data.map((icon, i) => {
                    return (
                      <ProductDetailButtonInfoComponent
                        key={i}
                        iconData={icon}
                      />
                    );
                  })}
                </div>
                <div className="pdf-si-btns-container-mobile">
                  <CarouselDos
                    children={elements}
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                    isAutoPlaying={true}
                    hasDotButtons={false}
                    hasLeftButton={false}
                    hasRightButton={false}
                    hasMediaButton={false}
                    hasIndexBoard={false}
                    hasThumbnails={false}
                    hasSizeButton={false}
                    transitionSpeed={0.1}
                  />
                </div>
              </div>
            )}
            <div className="pdf-third-info-container">
              {sectionData && sectionData.fsd_has_complement_info && (
                <>
                  <h1 className="pdf-ti-title">
                    {sectionData.fsd_complement_info_title}
                  </h1>
                  <ul className="pdf-ti-list-container">
                    {sectionData.fsd_complement_info_list.map((elm, i) => {
                      return (
                        <li className="pdf-ti-list-elm" key={i}>
                          {elm.fsd_ci_txt}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {sectionData.fsd_has_advice && (
                <p className="pdf-ti-info-txt">
                  <strong>Nota:</strong> Dependiendo de la aseguradora y el plan
                  contratado, podrás acceder a mayores beneficios.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ProductDetailFirstSection;
