import React, { useState } from "react";
import { Link } from "react-router-dom";

//Style
import "./JobOfferGridSection.css";

const JobOfferGridSection = ({ gridData }) => {
  const [cardData, setCardData] = useState(gridData);

  const [hover, setHover] = useState(false);

  const [filter, setFilter] = useState({
    search: "",
    area: "",
  });

  const handleSearchInput = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredData = gridData.filter((card) => {
      const titleMatch = card.title
        .toLowerCase()
        .includes(filter.search.toLowerCase());
      const areaMatch = filter.area === "" || card.area === filter.area;
      return titleMatch && areaMatch;
    });

    setCardData(filteredData);
  };

  return (
    <section className="job-offer-grid-section">
      <div className="job-offer-grid-subcontainer">
        <h1 className="jog-title">Ofertas de Empleo Disponibles</h1>
        <div className="jog-content-container">
          <form
            onChange={handleSearchInput}
            onSubmit={handleSubmit}
            className="jog-c-filter-container"
          >
            <label className="jog-c-f-search-container">
              <input
                type="text"
                className="jog-c-f-s-input"
                placeholder="Buscar"
                name="search"
              />
              <button
                type="submit"
                title="Buscar"
                className="jog-c-f-s-search-btn"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <img
                  src={!hover ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/job-offer-page/job-offer-main-page/assets/search-icon-hover.webp" : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/job-offer-page/job-offer-main-page/assets/search-icon.webp"}
                  alt=""
                  className="jog-c-f-s-sb-icon"
                />
              </button>
            </label>
            <label className="jog-c-f-select-container">
              <select name="area" id="" className="jog-c-f-select">
                <option value="" className="jog-c-f-opt">
                  Todas las Areas
                </option>
                <option value="Técnologia" className="jog-c-f-opt">
                  Técnologia
                </option>
                <option value="Comercial" className="jog-c-f-opt">
                  Comercial
                </option>
                <option value="Operativo" className="jog-c-f-opt">
                  Operativo
                </option>
                <option value="Técnico" className="jog-c-f-opt">
                  Técnico
                </option>
              </select>
            </label>
          </form>
          <div className="jog-c-grid-container">
            {cardData.map((card, i) => {
              return (
                <div key={i} className="jog-c-g-card-container">
                  <h1 className="jog-c-g-card-title">{card.title}</h1>
                  <div className="jog-c-g-card-type-container">
                    <p className="jog-c-g-ct-txt">{card.modality}</p>
                  </div>
                  <div className="jog-c-g-btn-elm-container">
                    <div className="jog-c-g-card-type-container">
                      <p className="jog-c-g-ct-txt">Area: {card.area}</p>
                    </div>
                    <Link
                      className="jog-c-g-be-btn"
                      title="Ver Detalles"
                      to={card.redirect}
                    >
                      Ver Detalles
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobOfferGridSection;
