import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import companyInsuranceGridData from "../../json/InsurancesCompanyGridData.json";
import companyInsuranceMobileGridData from "../../json/InsurancesCompanyMobileGridData.json";

//Style
import "./InsuranceCompanyGridSection.css";

//Components
import InsuranceCompanyGridComponent from "../InsuranceCompanyGridComponent/InsuranceCompanyGridComponent";

const InsuranceCompanyGridSection = () => {
  const elements = [];

  companyInsuranceMobileGridData &&
    companyInsuranceMobileGridData.data.map((insurance) => {
      return elements.push(<InsuranceCompanyGridComponent data={insurance} />);
    });

  return (
    <section className="insurance-company-grid-section">
      <div className="insurance-company-grid-subcontainer">
        <h1 className="icg-title">
          Nuestros <strong>PRODUCTOS</strong>
        </h1>
        <div className="icg-grid-component-container">
          {companyInsuranceGridData.data.map((insurance, i) => {
            return <InsuranceCompanyGridComponent key={i} data={insurance} />;
          })}
        </div>
        <div className="icg-grid-component-mobile-container">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
      <div className="section-skew"></div>
    </section>
  );
};

export default InsuranceCompanyGridSection;
