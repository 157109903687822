import React, { useState } from "react";
import { Link } from "react-router-dom";

//Style
import "./InsuranceCompanyGridComponent.css";

const InsuranceCompanyGridComponent = ({ data }) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={data.href}
      className="insurance-company-grid-elm-container"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {data.titlePos === "top" && (
        <h1 className="icg-elm-title top">{data.title}</h1>
      )}
      <img
        src={hover ? data.iconHover : data.icon}
        alt=""
        className="icg-elm-img"
      />
      {data.titlePos === "bottom" && (
        <h1 className="icg-elm-title bottom">{data.title}</h1>
      )}
    </Link>
  );
};

export default InsuranceCompanyGridComponent;
