import React, { useState } from "react";

//Style
import "./Footer.css";

//Data
import footerContentData from "../../json/FooterContentData.json";

const Footer = () => {
  const [hovered, setHovered] = useState({
    whatsapp: false,
    instagram: false,
    facebook: false,
    tiktok: false,
    linkedin: false,
    youtube: false,
  });

  return (
    <footer className="footer-section">
      <div className="footer-section-container fs-first">
        <div className="fs-subcontainer fss-first">
          <div className="fs-contact-info-container">
            <p>Síguenos:</p>
            <div className="fs-social-media-container">
              <a
                href="https://api.whatsapp.com/message/2GXY5SGT76PRM1?autoload=1&app_absent=0"
                target="_blank"
                title="Whatsapp"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ whatsapp: true })}
                onMouseOut={() => setHovered({ whatsapp: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.whatsapp
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/wa-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/wa-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/cavcaseguros/?hl=es-la"
                target="_blank"
                title="Instagram"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ instagram: true })}
                onMouseOut={() => setHovered({ instagram: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.instagram
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/instagram-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/instagram-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
              <a
                href="https://www.facebook.com/CavcaSeguros"
                target="_blank"
                title="Facebook"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ facebook: true })}
                onMouseOut={() => setHovered({ facebook: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.facebook
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/fb-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/fb-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
              <a
                href="https://www.tiktok.com/@cavca_seguros"
                target="_blank"
                title="TikTok"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ tiktok: true })}
                onMouseOut={() => setHovered({ tiktok: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.tiktok
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/tiktok-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/tiktok-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
              <a
                href="https://es.linkedin.com/company/cavcaseguros"
                target="_blank"
                title="LinkedIn"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ linkedin: true })}
                onMouseOut={() => setHovered({ linkedin: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.linkedin
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/linkedin-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/linkedin-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCEZbI26ETomAKTwsVutgb0w"
                target="_blank"
                title="Youtube"
                className="fs-sm-anchor"
                onMouseOver={() => setHovered({ youtube: true })}
                onMouseOut={() => setHovered({ youtube: false })}
                rel="noreferrer"
              >
                <img
                  className="fs-sm-icon"
                  src={
                    hovered.youtube
                      ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/hover/yt-icon-footer-hover.webp"
                      : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/footer/assets/yt-icon-footer.webp"
                  }
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="fs-contact-info-container">
            <p>Contáctanos:</p>
            <p>318 850 2710</p>
          </div>
          <div className="fs-contact-info-container">
            <p>Correo Electrónico:</p>
            <p>servicioalcliente@cavca.com.co</p>
          </div>
        </div>
      </div>
      <div className="footer-section-container fs-second">
        <div className="fs-subcontainer fss-second">
          {footerContentData.data.map((col, i) => {
            return (
              <div key={i} className="fs-footer-content-container">
                <h1 className="fs-fc-title">{col.title}</h1>
                {col.options.map((opt, i) => {
                  if (opt.isAnchor === true) {
                    return (
                      <a
                        className="fs-fc-anchor"
                        href={opt.to}
                        title={opt.text}
                        key={i}
                      >
                        {opt.text}
                      </a>
                    );
                  } else {
                    return <p className="fs-fc-no-anchor">{opt.text}</p>;
                  }
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer-section-container fs-third">
        <div className="fs-subcontainer">
          <p className="fs-copyright">
            ©Cavca Seguros {new Date().getFullYear()} Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
