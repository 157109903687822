import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//Data
import jobOfferPageData from "../json/JobOfferPageData.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import JobOfferPageBannerSection from "../components/JobOfferPageBannerSection/JobOfferPageBannerSection";
import JobOfferInfoSection from "../components/JobOfferInfoSection/JobOfferInfoSection";
import JobOfferFormComponent from "../components/JobOfferFormComponent/JobOfferFormComponent";

const JobOfferPageScreen = () => {
  const [jobOfferPage, setJobOfferPage] = useState({});

  const { name } = useParams();

  useEffect(() => {
    const selectedJobOffer = jobOfferPageData.data.filter(
      (job) => job.name === name
    );
    setJobOfferPage(selectedJobOffer[0]);
  }, [name]);

  return (
    <>
      {/* <NavbarInfo /> */}
      <Navbar />
      {jobOfferPage && (
        <>
          <JobOfferPageBannerSection
            bannerData={jobOfferPage.banner_section_data}
          />
          <JobOfferInfoSection
            sectionData={jobOfferPage.job_offer_info_section_data}
          />
          <JobOfferFormComponent offerName={name} />
        </>
      )}
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default JobOfferPageScreen;
