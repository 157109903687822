import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//Data
import productDetailData from "../json/ProductDetailData.json";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import ProductDetailBannerSection from "../components/ProductDetailBannerSection/ProductDetailBannerSection";
import ProductDetailFirstSection from "../components/ProductDetailFirstSection/ProductDetailFirstSection";
import ProductDetailSecondSection from "../components/ProductDetailSecondSection/ProductDetailSecondSection";
import ProductDetailThirdSection from "../components/ProductDetailThirdSection/ProductDetailThirdSection";
import ProductDetailFourthSection from "../components/ProductDetailFourthSection/ProductDetailFourthSection";

const ProductDetailScreen = () => {
  const [productDetailPage, setProductDetailPage] = useState({});

  const { name } = useParams();

  useEffect(() => {
    const selectedDetailProduct = productDetailData.data.filter(
      (product) => product.name === name
    );
    setProductDetailPage(selectedDetailProduct[0]);
  }, [name]);

  return (
    <>
      <Navbar />
      {productDetailPage && (
        <>
          <ProductDetailBannerSection
            bannerImg={productDetailPage.banner_img}
          />
          <ProductDetailFirstSection
            sectionData={productDetailPage.first_section_data}
          />
          {productDetailPage.has_second_section && (
            <ProductDetailSecondSection
              sectionData={productDetailPage.second_section_data}
            />
          )}
          {productDetailPage.has_third_section && (
            <ProductDetailThirdSection
              sectionData={productDetailPage.third_section_data}
            />
          )}
          {productDetailPage.has_fourth_section && (
            <ProductDetailFourthSection
              sectionData={productDetailPage.fourth_section_data}
            />
          )}
          <InsurancePartnerSection />
        </>
      )}
      <Footer />
    </>
  );
};

export default ProductDetailScreen;
