import React, { useState, useEffect } from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./TeamPeopleSection.css";

const TeamPeopleSection = ({ sectionData }) => {
  const [sortedPeople, setSortedPeople] = useState([]);

  useEffect(() => {
    if (
      sectionData &&
      sectionData.people_data &&
      sectionData.people_data.length > 0
    ) {
      const sortedPeople = sectionData.people_data.slice().sort((a, b) => {
        const nameA = a.person_name.toLowerCase();
        const nameB = b.person_name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setSortedPeople(sortedPeople);
    }
  }, [sectionData]);

  const elements = [];

  sortedPeople &&
    sortedPeople.map((person) => {
      return elements.push(
        <div className="tp-person-card-container">
          <img src={person.person_img} alt="" className="tp-pc-img" />
          <div className="tp-pc-txt-container">
            <h1 className="tp-pc-txt-title">{person.person_name}</h1>
            <p className="tp-pc-txt-subtitle">{person.person_job}</p>
          </div>
        </div>
      );
    });

  return (
    <section className="team-people-section">
      <div className="team-people-subcontainer">
        <h1 className="tp-title">{sectionData.title}</h1>
        <div className="tp-content-container">
          {sortedPeople.map((person, i) => {
            return (
              <div className="tp-person-card-container" key={i}>
                <img src={person.person_img} alt="" className="tp-pc-img" />
                <div className="tp-pc-txt-container">
                  <h1 className="tp-pc-txt-title">{person.person_name}</h1>
                  <p className="tp-pc-txt-subtitle">{person.person_job}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="tp-content-container-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
      </div>
    </section>
  );
};

export default TeamPeopleSection;
