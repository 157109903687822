import React from "react";

//Style
import "./ProductTabSection.css";

//Components
import ProductTabComponent from "../ProductTabComponent/ProductTabComponent";

const ProductTabSection = ({ fourthSectionData }) => {
  return (
    <section className="product-tab-section">
      <div className="product-tab-subcontainer">
        <h1 className="pt-title">
          {fourthSectionData && fourthSectionData.fourth_section_title}
        </h1>
        <div className="pt-tab-container">
          {fourthSectionData &&
            fourthSectionData.fourth_section_tab_elms.map((tabData, i) => {
              return <ProductTabComponent tabData={tabData} key={i} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default ProductTabSection;
