import React from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import "./ProductDetailThirdSection.css";

const ProductDetailThirdSection = ({ sectionData }) => {
  const elements = [];

  sectionData &&
    sectionData.tsd_benefit_icon_data.map((benefit) => {
      return elements.push(
        <div className="pdt-tic-elm-container">
          <img src={benefit.tsd_bid_icon} alt="" className="pdt-tic-e-img" />
          <h1 className="pdt-tic-e-title">{benefit.tsd_bid_title}</h1>
        </div>
      );
    });

  return (
    <section className="product-detail-third-section">
      {sectionData && (
        <>
          <img
            src={sectionData.tsd_small_banner}
            alt=""
            className="pdt-small-banner"
          />
          <div className="product-detail-third-subcontainer">
            {sectionData.tsd_has_content_txt && (
              <div className="pdt-type-txt-content-container">
                <h1 className="pdt-ttc-title">
                  {sectionData.tsd_content_txt_data.tsd_ctd_title}
                </h1>
                {sectionData.tsd_content_txt_data.tsd_ctd_content.map(
                  (txt, i) => {
                    return (
                      <p key={i} className="pdt-ttc-txt">
                        {txt.tsd_cdt_txt}
                      </p>
                    );
                  }
                )}
              </div>
            )}
            {sectionData.tsd_has_benefit_icon && (
              <div
                className={`pdt-type-imgs-content-container ${
                  sectionData.tsd_benefit_grid_cols === 3
                    ? "pdt-tic-three-col"
                    : sectionData.tsd_benefit_grid_cols === 4
                    ? "pdt-tic-four-col"
                    : ""
                }`}
              >
                {sectionData.tsd_benefit_icon_data.map((benefit, i) => {
                  return (
                    <div className="pdt-tic-elm-container" key={i}>
                      <img
                        src={benefit.tsd_bid_icon}
                        alt=""
                        className="pdt-tic-e-img"
                      />
                      <h1 className="pdt-tic-e-title">
                        {benefit.tsd_bid_title}
                      </h1>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="pdt-type-imgs-content-container-mobile">
              <CarouselDos
                children={elements}
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
                isAutoPlaying={true}
                hasDotButtons={false}
                hasLeftButton={false}
                hasRightButton={false}
                hasMediaButton={false}
                hasIndexBoard={false}
                hasThumbnails={false}
                hasSizeButton={false}
                transitionSpeed={0.1}
              />
            </div>
            {sectionData.tsd_has_advice_txt && (
              <p className="pdt-advice-txt">
                <strong>Recuerda que</strong>…{" "}
                {sectionData.tsd_benefit_advice_txt}
              </p>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default ProductDetailThirdSection;
