import React, { useState } from "react";

//Style
import "./ProductTabComponent.css";

const ProductTabComponent = ({ tabData }) => {
  const [showTabInfo, setShowTabInfo] = useState(false);

  return (
    <div className="product-tab-container">
      <div
        className={`product-tab-header-container ${showTabInfo && "pth-open"}`}
        onClick={() => setShowTabInfo(!showTabInfo)}
      >
        <img src={showTabInfo ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/product-page/assets/less-tab-icon.webp" : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/product-page/assets/more-tab-icon.webp"} alt="" className="pth-icon" />
        <h1 className={`pth-title ${showTabInfo && "pth-title-open"}`}>
          {tabData.fste_title}
        </h1>
      </div>
      <div
        className={`product-tab-content-container ${
          !showTabInfo ? "ptc-close" : "ptc-open"
        }`}
      >
        {tabData &&
          tabData.fste_content.map((tab, i) => {
            return (
              <p key={i} className="ptc-txt">
                {tab.fste_c_txt}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default ProductTabComponent;
