import React from "react";

//Style
import "./FirstVideoSection.css";

const FirstVideoSection = () => {
  return (
    <section className="first-video-section">
      <div className="first-video-subcontainer">
        <h1 className="fv-title">
          ¡Más de una década comprometidos con tu seguridad y bienestar!
        </h1>
        <div className="fv-media-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/U56Hz9GfsFc?si=sakV1LGJJjsk2CsY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          {/* <video className="fv-video" controls>
            <source src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/people-main-home/videos/fv-video.mp4" type="video/mp4" />
          </video> */}
        </div>
      </div>
    </section>
  );
};

export default FirstVideoSection;
