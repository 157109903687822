import React, { useState } from "react";
import { Link } from "react-router-dom";

//Style
import "./NavbarHome.css";

const NavbarHome = () => {
  const [viewMenu, setViewMenu] = useState(false);

  const showMenu = () => {
    setViewMenu(!viewMenu);
  };

  return (
    <nav className="navbar-home-container">
      <div className="navbar-home-subcontainer">
        <div className="logo-container">
          <Link to="/">
            <img className="logo" src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/assets/logo-cavca.webp" alt="" />
          </Link>
        </div>
        <div className="nh-menus-container">
          <Link
            to="https://cavca.my.salesforce.com/"
            className="nh-menu"
            target="_blank"
            title="Acceso Funcionarios"
          >
            Acceso Funcionarios
          </Link>
          <Link to="/nosotros" className="nh-menu" title="Quienes Somos">
            Quienes Somos
          </Link>
          <Link to="/contactanos" className="nh-menu" title="Contáctanos">
            Contáctanos
          </Link>
          <Link
            to="/ofertas-de-empleo"
            className="nh-menu job-offer-btn"
            title="Ofertas de Empleo"
          >
            Ofertas de Empleo
          </Link>
        </div>
        <div onClick={() => showMenu()} className="ham-menu-container">
          <img
            src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/ham-menu-icon.webp"
            alt=""
            className="ham-menu-icon"
            loading="lazy"
          />
        </div>
        <div
          className={
            viewMenu
              ? "menu-options-container open-menu"
              : "menu-options-container close-menu"
          }
        >
          <div className="anchor-container underline navbar-menu">
            <Link
              onClick={() => setViewMenu(false)}
              className="anchor"
              to="https://cavca.my.salesforce.com/"
            >
              Acceso Funcionarios
            </Link>
          </div>
          <div className="anchor-container underline navbar-menu">
            <Link
              onClick={() => setViewMenu(false)}
              className="anchor"
              to="/quienes-somos"
            >
              Quienes Somos
            </Link>
          </div>
          <div className="anchor-container underline navbar-menu">
            <Link
              onClick={() => setViewMenu(false)}
              className="anchor"
              to="/contactanos"
            >
              Contáctanos
            </Link>
          </div>
          <div className="anchor-container underline navbar-menu">
            <Link
              onClick={() => setViewMenu(false)}
              className="anchor"
              to="/ofertas-de-empleo"
            >
              Ofertas de Empleo
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarHome;
