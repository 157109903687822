import React, { useState } from "react";
import { Link } from "react-router-dom";

// Style
import "./InsurancesGridComponent.css";

const InsurancesGridComponent = ({ gridBtn }) => {
  const [hoveredIcons, setHoveredIcons] = useState(
    gridBtn.steps.map(() => null)
  );

  const handleMouseEnter = (stepIndex, iconIndex, iconHover) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[stepIndex] = newHoveredIcons[stepIndex] || [];
    newHoveredIcons[stepIndex][iconIndex] = iconHover;
    setHoveredIcons(newHoveredIcons);
  };

  const handleMouseLeave = (stepIndex, iconIndex) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[stepIndex] = newHoveredIcons[stepIndex] || [];
    newHoveredIcons[stepIndex][iconIndex] = null;
    setHoveredIcons(newHoveredIcons);
  };

  return (
    <div className="insurance-grid-component-container">
      {gridBtn.steps.map((item, stepIndex) => {
        let indexBottom = stepIndex;
        return (
          <div key={stepIndex} className="igc-row-icons">
            {item.icons.map((icon, iconIndex) => {
              return (
                <Link
                  key={iconIndex}
                  className={`igc-icon-btn ${
                    indexBottom === 0 ? "line-bottom" : ""
                  } ${iconIndex > 0 ? "line-left" : ""}`}
                  title={icon.title}
                  to={icon.href}
                  onMouseEnter={() =>
                    handleMouseEnter(stepIndex, iconIndex, icon.iconHover)
                  }
                  onMouseLeave={() => handleMouseLeave(stepIndex, iconIndex)}
                >
                  <img
                    name={iconIndex}
                    className="igc-icon"
                    src={
                      hoveredIcons[stepIndex] &&
                      hoveredIcons[stepIndex][iconIndex] === icon.iconHover
                        ? icon.iconHover
                        : icon.icon
                    }
                    alt=""
                  />
                  <p className="igc-subtitle">{icon.subtitle}</p>
                </Link>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default InsurancesGridComponent;
