import React from "react";

//Style
import "./InsurancePeopleGridSection.css";

//Data
import insurancesGridData from "../../../json/InsurancesGridData.json";
import insurancesGridMobileData from "../../../json/InsuranceGridMobileData.json";

//Components
import InsurancesGridComponent from "../../InsurancesGridComponent/InsurancesGridComponent";
import InsuranceGridMobileComponent from "../../InsuranceGridMobileComponent/InsuranceGridMobileComponent";

const InsurancePeopleGridSection = () => {
  return (
    <section className="insurance-people-grid-section">
      <div className="insurance-people-grid-subcontainer">
        <h1 className="ipg-title">Todos Los Seguros Para Todos Los Momentos</h1>
        <p className="ipg-text">
          Cotizamos Rápido. Precios Increíbles. Asesoramos Con Pasión.
        </p>
        <div className="ipg-insurance-btn-container">
          <InsurancesGridComponent gridBtn={insurancesGridData.data} />
        </div>
        <div className="ipg-insurance-mobile-btn-container">
          <InsuranceGridMobileComponent
            gridBtn={insurancesGridMobileData.data}
          />
        </div>
      </div>
    </section>
  );
};

export default InsurancePeopleGridSection;
