import React, { useState } from "react";

//Style
import "./ProductDetailButtonInfoComponent.css";

const ProductDetailButtonInfoComponent = ({ iconData }) => {
  const [showBtnInfo, setShowBtnInfo] = useState(false);

  return (
    <div className="product-detail-button-info-component">
      <img src={iconData.fsd_icon_img} alt="" className="pdbi-img" />
      {iconData.fsd_has_title && (
        <h1 className="pdbi-title">{iconData.fsd_icon_title}</h1>
      )}
      {iconData.fsd_icon_has_button && (
        <button
          className={showBtnInfo ? "pdbi-title-btn open" : "pdbi-title-btn"}
          onClick={() => setShowBtnInfo(!showBtnInfo)}
        >
          <img
            className="pdbi-tb-icon"
            src={showBtnInfo ? "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/product-detail-page/assets/less-product-detail-page.webp" : "https://pagina-cavca-seguros.s3.amazonaws.com/main-page/product-detail-page/assets/more-product-detail-page.webp"}
            alt=""
          />
          <p>{iconData.fsd_icon_button_data.fsd_ibd_title}</p>
        </button>
      )}
      <div
        className={`pdbi-tb-content-container ${
          showBtnInfo && "pdbi-tb-show-content"
        }`}
      >
        {iconData.fsd_icon_has_button && iconData.fsd_icon_button_data.fsd_ibd_content.map((txt, i) => {
          return (
            <>
              <h1 key={i} className="pdbi-tb-title">
                {txt.fsd_ibd_title}
              </h1>
              <p className="pdbi-tb-content">{txt.fsd_ibd_txt}</p>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProductDetailButtonInfoComponent;
