import React from "react";
import { Link } from "react-router-dom";

//Style
import "./CompanyHomeThirdSectio.css";

const CompanyHomeThirdSection = () => {
  return (
    <section className="company-home-third-section">
      <div className="company-home-third-subcontainer">
        <div className="cht-subcontainer">
          <h1 className="cht-title white">
            Seguros <strong>CONSTRUCCIÓN</strong>
          </h1>
          <p className="cht-content-txt white">
            El seguro todo riesgo te acompaña donde vayas, cualquier incidente
            que tengas por donde transites. El seguro todo riesgo te acompaña
            donde vayas, cualquier incidente que tengas por donde transites.
          </p>
          <div className="cht-btn-container">
            <Link
              className="cht-btn"
              to="/seguros-empresariales/productos/construccion"
              title="Acceder"
            >
              Acceder
            </Link>
          </div>
        </div>
        <img
          src="https://pagina-cavca-seguros.s3.amazonaws.com/main-page/company-main-page/bgs/company-home-third-section-deco.webp"
          alt=""
          className="cht-deco-img"
        />
      </div>
      <div className="skew-bottom"></div>
    </section>
  );
};

export default CompanyHomeThirdSection;
