import React from "react";
import GridCarousel from "react-grid-carousel";

//Data
import prizesCarouselData from "../../../json/PrizesCarouselData.json";

//Style
import "./PrizesCarouselSection.css";

const PrizesCarouselSection = () => {
  return (
    <section className="prizes-carousel-section">
      <div className="prizes-carousel-subcontainer">
        <h1 className="pc-title">
          Más de 13 años de experiencia en el sector y más de 24.000 clientes
          asegurados
        </h1>
        <div className="pc-carousel-container">
          <GridCarousel
            cols={4}
            rows={1}
            gap={10}
            loop
            autoplay={4000}
            responsiveLayout={[
              {
                breakpoint: 767,
                cols: 1,
              },
              {
                breakpoint: 1023,
                cols: 3,
              },
            ]}
          >
            {prizesCarouselData &&
              prizesCarouselData.data.map((prize, i) => {
                return (
                  <GridCarousel.Item key={i}>
                    <div className="pc-carousel-item-container">
                      <img className="pcci-img" width="100%" src={prize.img} alt="" />
                      <p className="pcci-subtitle">{prize.subtitle}</p>
                    </div>
                  </GridCarousel.Item>
                );
              })}
          </GridCarousel>
        </div>
      </div>
    </section>
  );
};

export default PrizesCarouselSection;
