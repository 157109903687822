import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import PeopleHomeScreen from "./pages/PeopleHomeScreen";
import ProductPageScreen from "./pages/ProductPageScreen";
import ProductDetailScreen from "./pages/ProductDetailScreen";
import AboutUsScreen from "./pages/AboutUsScreen";
import TeamScreen from "./pages/TeamScreen";
import ContactScreen from "./pages/ContactScreen";
import JobOfferScreen from "./pages/JobOfferScreen";
import JobOfferPageScreen from "./pages/JobOfferPageScreen";
import PrivacyPolicyScreen from "./pages/PrivacyPolicyScreen";
import CompanyHomeScreen from "./pages/CompanyHomeScreen";
import SalesforceBgScreen from "./pages/SalesforceBgScreen";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/seguros-personales"
          element={<PeopleHomeScreen />}
        />
        <Route
          exact
          path="/seguros-personales/productos/:name"
          element={<ProductPageScreen />}
        />
        <Route
          exact
          path="/seguros-empresariales/productos/:name"
          element={<ProductPageScreen />}
        />
        <Route
          exact
          path="/seguros-personales/productos/:product_name/informacion/:name"
          element={<ProductDetailScreen />}
        />
        <Route
          exact
          path="/seguros-empresariales/productos/:product_name/informacion/:name"
          element={<ProductDetailScreen />}
        />
        <Route exact path="/nosotros" element={<AboutUsScreen />} />
        <Route exact path="/nosotros/equipo/:name" element={<TeamScreen />} />
        <Route exact path="/contactanos" element={<ContactScreen />} />
        <Route exact path="/ofertas-de-empleo" element={<JobOfferScreen />} />
        <Route
          exact
          path="/ofertas-de-empleo/:name"
          element={<JobOfferPageScreen />}
        />
        <Route
          exact
          path="/politicas-de-privacidad"
          element={<PrivacyPolicyScreen />}
        />
        <Route exact path="*" element={<Home />} />
        <Route
          exact
          path="/seguros-empresariales"
          element={<CompanyHomeScreen />}
        />
        <Route
          exact
          path="/salesforce-background"
          element={<SalesforceBgScreen />}
        />
      </Routes>
    </Router>
  );
};

export default App;
