import React, { useEffect, useState } from "react";

//Components
import Navbar from "../components/Navbar/Navbar";
import InsurancePartnerSection from "../components/PeopleHomeComponents/InsurancePartnerSection/InsurancePartnerSection";
import Footer from "../components/Footer/Footer";
import ContactSection from "../components/ContactSection/ContactSection";
import StaticBannerComponent from "../components/StaticBannerComponent/StaticBannerComponent";
import ContactLocationSection from "../components/ContactLocationSection/ContactLocationSection";

const ContactScreen = () => {
  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <Navbar />
      <StaticBannerComponent
        bannerImg={"https://pagina-cavca-seguros.s3.amazonaws.com/main-page/contact-page/banner/contact-banner-img-test.webp"}
        height={wide < 768 ? "272px" : "560px"}
      />
      <ContactSection />
      <ContactLocationSection />
      <InsurancePartnerSection />
      <Footer />
    </>
  );
};

export default ContactScreen;
